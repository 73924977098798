import { Injectable } from '@angular/core';  

@Injectable({
  providedIn: 'root'
})
export class RunService {
  isMapDetail = false
  
  appStartStatus = false

  data: any = {}

  constructor() { }

  set(key: string, data: any): void {
    this.data[key] = data
  } 

  get(key: string): any {
    return this.data[key]
  }

  delete(key: string): void {
    delete this.data[key]
  }
   
}
