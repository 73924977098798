<div class="popup-wrapper" *ngIf="show" (click)="closeModal()">
    <div class="popup-body" (click)="noC($event)">
      <div class="top-bg bgimg">
        <img src="assets/imgs/8/pp-top-bg.png"/>
        <div class="close" (click)="closeModal()"></div>
      </div>
      <div class="content-wrapper" style="background-image: url(assets/imgs/8/pp-rp-bg.png);" >
        <div class="cw-body">
            <ng-content></ng-content>
        </div>
      </div>
      <div class="bottom-bg bgimg">
        <img src="assets/imgs/8/pp-bottom-bg.png"/>
  
      </div>
    </div>
  </div>
  