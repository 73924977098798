import { Injectable } from '@angular/core';
import { DialogService } from 'src/app/dialog';
 
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private dialogService: DialogService
  ) { }

  isSmartDevice() {
    let userAgentInfo = navigator.userAgent
    let mobileAgents  = [ "Android", "iPhone", "iPad","iPod","Silk","BlackBerry","Opera Mini","IEMobile"]
    let flag = false
    for (let v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
            flag = true
            break
        }
    }
    return flag
  }

  isWx() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
  }

  isMiniProgram() {
    return navigator.userAgent.toLowerCase().indexOf('miniprogram') !== -1;
  }

  isAndroid(){
    return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; //android终端
  }

  strSplice(str) {
    let str1 = str.substring(0, 4);
    let str2 = str.substring(4, 8);
    let str3 = str.substring(8, 12);
    return str1 + " " + str2 + " " + str3;
  }

  setShowPc() {
    localStorage.setItem('_is_show_pc_tag','is_show')
  }
  getShowPc() {
    const isShowPc = localStorage.getItem('_is_show_pc_tag')
    if (isShowPc === null) return false

    return true
  }
  
  iswechat(){
    const ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') == -1) {
        console.log("不在微信或者小程序中")
        return false;
      } else {
        return true;
      }
  }
}
