<div class="container">
   <div class="month-list">
      <div  *ngFor="let item of data" class="month-nav">
         <div class="content-title">
            <img src="/assets/2022/imgs/activety/calendar_icon@2x.png" alt="" width="48px" height="48px">
            <span class="title-content">{{item.month}}月</span>
         </div>
         <div class="dates">
            <div class="date" *ngFor="let date of item.days;" [ngClass]="info.day == date.day ? 'active' : ''" (click)="changeDate(date)">
               <img [src]="'/assets/2022/imgs/activety/card' + (info.day == date.day ? date.week_no +'_active' : date.week_no) + '@2x.jpg'" alt="">
               <span class="date-number">{{date.day}}</span>
            </div>
         </div>
      </div>
   </div>
   <div>
      <div class="row" *ngFor="let row of info.rows;let last=last">
         <img [src]="'/assets/2022/imgs/activety/timeicon'+row.status+'@2x.png'" alt="" class="status-img">
         <div class="time">{{row.stime}}-{{row.etime}}</div>
         <div class="row-data">
            <img [src]="S_ROOT_URL + row.cover + '?x-oss-process=image/resize,w_400'" alt="" width="437px" height="500px" [ngClass]="row.status == 2 ? 'active' : ''" (click)="clickRow(row)">
            <div class="row-data-title" *ngFor="let subject of row.subject_rich">{{subject}}</div>
            <div class="label" [ngClass]="'status-other-'+row.status">
               <img src="" alt="" *ngIf="row.status == 2" src="/assets/2022/imgs/activety/act_liveicon@2x.png">
               {{status_map[row.status]}}
            </div>
            <div class="label praise">
               人气：{{row.pv}}
            </div>
            <div class="desc">
               <div>
                  <div class="desc-label">直播时间：</div>{{row.date}} {{row.stime}}-{{row.etime}}
               </div>
               <div style="margin-top:16px" [ngStyle]="{'margin-bottom': !last ? '50px' : ''}">
                  <div class="desc-label">参会人/嘉宾：</div>{{row.guest}}
               </div>
            </div>
         </div>
         <div class="left-line"></div>
      </div>
   </div>
</div>


<div class="popup-wrapper" *ngIf="qrcode_modal.show" (click)="qrcode_modal.show = false">
   <div class="popup-body" (click)="noC($event)">
      <div class="close" (click)="qrcode_modal.show = false">✖️</div>
      <div class="modal">
            <!-- <img [src]="QR_ROOT_URL + qrcode_modal.url" alt=""> -->
            <img src="/assets/2022/imgs/code2@2x.jpg" alt="">
      </div>
   </div>
 </div>
