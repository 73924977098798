import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SharedHammerConfig } from './shared.hammer.config';
import { HtmlPipe, TfPipe } from './pipes';
import { LoaddingComponent, ToastComponent, ModalComponent, ErrorComponent, TestComponent, LayoutMainComponent, LayoutMain2022Component, LayoutMain2023Component, LayoutMain2024Component } from './components';
import { MaintainComponent } from './components/maintain';

@NgModule({
  declarations: [
    LoaddingComponent,
    ToastComponent,
    ModalComponent,
    ErrorComponent,
    MaintainComponent,
    TestComponent,
    LayoutMainComponent,
    LayoutMain2022Component,
    LayoutMain2023Component,
    LayoutMain2024Component,
    TfPipe,
    HtmlPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HammerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    LoaddingComponent,
    ToastComponent,
    ModalComponent,
    ErrorComponent,
    MaintainComponent,
    TestComponent,
    LayoutMainComponent,
    LayoutMain2022Component,
    LayoutMain2023Component,
    TfPipe,
    HtmlPipe,
    LayoutMain2024Component,
  ],
  entryComponents: [
    LoaddingComponent,
    ToastComponent,
    ModalComponent
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: SharedHammerConfig }
  ]
})
export class SharedModule {}
