import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hots-2022',
  templateUrl: './hots.component.html',
  styleUrls: ['./hots.component.styl']
})
export class Hots2022Component implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  rows:any [];
  loaded = false
  sindex = 0

  ngOnInit() {
    this.getdata();
  }

  getdata(){
    this.http.get('/data/top').subscribe((res:any) => {
      this.rows = res.exhibitor ? Object.values(res.exhibitor) : [];
      console.log(this.rows)
      this.loaded = true
    })
  }

  gotab(index){
    this.sindex = index
  }
}
