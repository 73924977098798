<app-layout-main [navi]='1' *ngIf="loaded">

  <div class="loookguide-wrapper gl-min-width">
    <div class="loookguide-content gl-container-width">
      <div class="loookguide-body">
        <!-- <div class="pdf-img">
          <img src="assets/imgs/2/pdf.png" />
        </div>
        <div class="nav-group">
          <div class="pre-group btn-group btn-disable">
            <div class="pre-img img">
              <img src="assets/imgs/2/arrow-dis.png"/>
            </div>
            <div class="txt">上一页</div>
          </div>
          <div class="next-group btn-group btn-enable">
            <div class="txt">下一页</div>
            <div class="next-img img">
              <img src="assets/imgs/2/arrow-en.png"/>
            </div>
          </div>
        </div> -->

      </div>
      <div class="look-wrap">
        <div class="look" *ngFor="let item of rows" (click)="gopdf(item)">
          <div class="name">{{item.name}}</div>
          <img [src]="S_ROOT_URL + item.img + osslink" />
        </div>
      </div>
      <!-- <div class="title">
        <img src="assets/imgs/2/title.png"/>
      </div>
      <div class="loookguide-bg">
        <img src="assets/imgs/2/pdf-bg.png"/>
      </div> -->
    </div>
  </div>

</app-layout-main>
