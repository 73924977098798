import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-activety-2022-tab2',
  templateUrl: './tab2.component.html',
  styleUrls: ['./tab2.component.styl']
})
export class ActivetyTab2Component implements OnInit {

  QR_ROOT_URL = environment.QR_ROOT_URL
  S_ROOT_URL = environment.S_ROOT_URL

  qrcode_modal = {
    show: false,
    url: '',
  }

  status_map = {
    1: '待播中',
    2: '直播中',
    3: '直播结束',
    4: '直播结束',
  }

  info:any = {
    rows: [],
  }

  data:any = []

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getData();
  }


  getData() {
    this.http.get('/pc/gflt').subscribe((res: any) => {
      this.data = res
      this.changeDate(this.data[0].days[0]);
     })
  }

  changeDate(item) {
    if(item) this.info = item
  }
  
  clickRow(row) {
    if (row && (row.status == 2 || row.status == 4)) {
      this.qrcode_modal.show = true
      this.qrcode_modal.url = row.img
    }
  }

  noC(e) {
    e.stopPropagation();
    return false;
  }

}
