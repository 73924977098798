<app-layout-main [navi]='12'>
  <div class="previous-wrapper gl-min-width">
    <div class="previous-content gl-container-width">
        <div class="ptitle">
          <img src="assets/imgs/13/title.png" />
        </div>
        <div class="pimg">
          <img src="https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com/app/edu-expo/index/wjjcpc.jpeg" />
        </div>
    </div>
  </div>
</app-layout-main>
