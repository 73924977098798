import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-previous-2022',
  templateUrl: './previous.component.html',
  styleUrls: ['./previous.component.styl']
})
export class Previous2022Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
