<div class="activety-type-list">
  <div 
      class="activety-type" 
      *ngFor="let activety_type of activety_types" 
      [ngClass]="activety_type.id == select_type_id ? 'active' : ''"
      (click)="changeType(activety_type.id)" >
      {{activety_type.name}}
   </div>
</div>
<div class="list"> 
   <div class="item" *ngFor="let item of data;let last=last" [ngStyle]="{'margin-right': !last ? '15px' : '0'}" (click)="showModal(item.id)">
      <img [src]="item.file + '?x-oss-process=image/resize,w_246'" alt="" width="246px">
      <div class="date">{{item.created}}</div>
      <div class="date-tag" *ngIf="item.target.length > 0">
        <div class="tag" *ngFor="let tag of item.target">{{tag}}</div>
      </div>
      <div class="title">{{item.name}}</div>
      <div class="title">{{item.subtitle}}</div>
   </div>
</div>

<app-modal-2022 [(show)]="show_modal">
   <div class="content">
      <div class="ptile">{{row.name}}</div>
      <div class="ptile">{{row.subtitle}}</div>
      <div class="tags" *ngIf="row.target.length > 0">
        <div class="tag" *ngFor="let tag of row.target">{{tag}}</div>
      </div>
      <div *ngIf="row.organizer.length > 0" class="org">
         <div *ngFor="let org of row.organizer">{{org}}</div>
      </div>
      <div class="orgs">
        <div class="org txt">{{row.author}}</div>
      </div>
      <div class="description" [innerHTML]="row.description"></div>
      <div *ngFor="let f of row.ffile" class="ffile">
         <img [src]="S_ROOT_URL + f.url + '?x-oss-process=image/resize,w_600'" alt="" *ngIf="f.type == 'image'" class="image">
         <video class="video" *ngIf="f.type == 'video'"
            controls
            disablePictureInPicture
         >
         <source [src]="f.url" type="video/mp4">
         <p>你的浏览器不支持 <code>Video</code> </p>
         </video>
      </div>
   </div>
     

</app-modal-2022>