import { M2022Module } from './m2022/m2022.module';
import { M2021Module } from './m2021/m2021.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NotificationInterceptor, HeaderInterceptor } from './shared/interceptors';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { SimpleReuseStrategy } from './app-routing.cache';
import { EchartsModule } from './echarts/echarts.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    M2021Module,
    M2022Module,
    EchartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: SimpleReuseStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
