<app-layout-main [navi]='8' *ngIf="loaded">
  <div class="news-detail-wrapper gl-min-width">
    <div class="news-detail-content gl-container-width">
        <div class="news-detail-body" style="background-image: url(assets/imgs/9/ndc-title-bg.png);">
          <div class="ndb-title" >
            <div class="title">{{row.name}}</div>
            <div class="time">发布时间: {{row.created}}</div>
          </div>
          <div class="ndb-body">
            <div class="ndb-content">

                <div class="editor" [innerHTML]="row.content | html"></div>

            </div>
            <!-- <div class="ndb-recommand-content">
              <div class="news-list">
                <div class="nltitle">相关新闻</div>
                <div class="nlrows">
                  <div class="nlrow" *ngFor="let item of [1,1,1]">
                    城乡教师双向多元流动，模式的探索模式的探索
                  </div>
                </div>
              </div>
              <div class="video-list">
                <div class="vltitle">相关直播</div>
                <div class="vlrows">
                  <div class="vlrow" *ngFor="let item of [1,1,1,1]">
                    <div class="vlrow-tile">
                      直播标题直播标题，直播标题直播标题直播标题直播
                    </div>
                    <div class="vlrow-poster">
                      <img src="assets/imgs/9/news-df1.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="news-detail-bg">
          <img src="assets/imgs/9/ndc-bg.png"/>
        </div>
    </div>
  </div>
</app-layout-main>
