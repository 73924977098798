<app-layout-main [navi]='7' *ngIf="loaded">
  <div class="red-resources-wrapper gl-min-width">
    <div class="red-resources-content gl-container-width">
        <div class="ptitle">
          <img src="assets/imgs/8/title.png" />
        </div>
        <div class="tag-wrapper">
          <div class="tag-content">
            <!-- <div class="tagitem" style="background-image: url(assets/imgs/8/tag2.png);">分类A</div>
            <div class="tagitem" style="background-image: url(assets/imgs/8/tag1.png);">分类B</div> -->
            <div class="tagitem noselected" *ngFor="let item of res;let index = index" [ngClass]="{'selected': sindex == index}"
              (click)="gotab(index)"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="rows-wrapper">
          <div class="rows-cols" *ngFor="let items of rows; let rindex = index">
            <div class="row-group" *ngFor="let item of items; let iindex = index" (click)="showdetail(rindex, iindex)">
              <div class="df-txt-group">
                <img [src]="item.acover + osslink"/>
                <div class="tags-group">
                  <div class="tags-rows">
                    <div class="tag" *ngFor="let tag of item.tags">{{tag}}</div>
                  </div>
                </div>
              </div>
              <div class="infor-group">
                <div class="ititle">{{item.jzname}}</div>
                <div class="intro">{{item.district}}</div>
                <div class="intro text-overflow-4">{{item.intro}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="red-resources-bg">
          <img src="assets/imgs/8/red-res-bg.png"/>
        </div>
    </div>
  </div>
</app-layout-main>

<div class="popup-wrapper" *ngIf="showPoup">
  <div class="popup-body">
    <div class="top-bg bgimg">
      <img src="assets/imgs/8/pp-top-bg.png"/>
      <div class="close" (click)="showPoup=false"></div>
    </div>
    <div class="content-wrapper" style="background-image: url(assets/imgs/8/pp-rp-bg.png);">
      <div class="cw-body">
        <div class="pname">{{showingrow.jzname}}</div>
        <div class="gap"><img src="assets/imgs/8/pp-gap.png"/></div>
        <div class="infors-wrapper">
          <div class="school txt" *ngIf="showingrow.name" style="background-image: url(assets/imgs/8/pp-school.png);">{{showingrow.name}}</div>
          <div class="point txt" *ngIf="showingrow.level && showingrow.level != '未定'" style="background-image: url(assets/imgs/8/pp-point.png);">{{showingrow.level}}</div>
          <div class="map txt" *ngIf="showingrow.address" style="background-image: url(assets/imgs/8/pp-map.png);">{{showingrow.address}}</div>
        </div>
        <div class="gap"><img src="assets/imgs/8/pp-gap.png"/></div>

        <div class="intro-wrapper">
          <div class="txt">  {{showingrow.intro}}</div>
          <div class="txt editor" [innerHTML]="showingrow.describe"></div>
          <div class="imgs">
            <img [src]="U_ROOT_URL + item + ossbiglink" *ngFor="let item of showingrow.file"/>
            <!-- <img src="assets/imgs/8/pp2.png"/> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-bg bgimg">
      <img src="assets/imgs/8/pp-bottom-bg.png"/>

    </div>
  </div>
</div>
