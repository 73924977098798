import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class MainIndexComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  res: any;
  loaded = false
  U_ROOT_URL = environment.U_ROOT_URL
  osslink = '?x-oss-process=image/resize,w_300';

  ngOnInit() {
    this.getdata();
  }

  activitys = []

  getdata(){
    this.http.get('/pc/index?year=2021').subscribe((res) => {
      this.res = res;
      this.loaded = true
    })
    this.http.get('/pc/activity').subscribe((res: any) => {
     this.activitys = res
    })
  }

  gopage(url){
    this.router.navigateByUrl(url)
  }

}
