import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-news-2022-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class NewsDetailComponent implements OnInit {

  U_ROOT_URL = environment.U_ROOT_URL

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  row :any = {
   
  }

  tags = []

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id')
    this.getdata(id)
  }

  getdata(id){
    this.http.get('/pc/news/' + id).subscribe((res) => {
      this.row = res
      if (this.row.tag) {
        this.tags = this.row.tag.split(',')
      }
    })
  }
}
