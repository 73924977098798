import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import videojs from 'video.js';

@Component({
  selector: 'app-index-2022',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class IndexComponent implements OnInit {

  player: videojs.Player;

  @ViewChild('livevideo') livevideo: ElementRef;
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  S_ROOT_URL = environment.S_ROOT_URL;

  // 开幕式状态
  EDU_EXPO_LIVE_STATUS_WAITING = 1;  // 待播中
  EDU_EXPO_LIVE_STATUS_LIVING = 2;   // 直播中
  EDU_EXPO_LIVE_STATUS_PADDING = 3;  // 直播结束无视频
  EDU_EXPO_LIVE_STATUS_END = 4;      // 直播结束有视频

  select_type_id = 1;

  inauguration: any = {
    status: this.EDU_EXPO_LIVE_STATUS_WAITING
  };

  activety_types = [
    {id: 1, name: '开幕式'},
    {id: 2, name: '高峰论坛'},
    {id: 3, name: '校园文化活动'},
  ];

  gflt_data = [

  ];

  res: any;
  loaded = false;
  U_ROOT_URL = environment.U_ROOT_URL;
  osslink = '?x-oss-process=image/resize,w_300';
  activitys = [];
  onlinelook = [
    {id: 1, name: '教育应用场景'},
    {id: 6, name: '数字化小课堂'},
    {id: 2, name: '教育"随申办"'},
    {id: 3, name: '金色学堂'},
    {id: 4, name: '在线人文行走'},
    {id: 5, name: '市民终生学习在线体验基地'},
  ];

  ngOnInit() {
    this.getdata();
  }

  getdata(){
    this.http.get('/pc/index2022').subscribe((res) => {
      this.res = res;
      if (this.res.activity.topic) { this.activitys.push(...this.res.activity.topic); }
      if (this.res.activity.school) { this.activitys.push(...this.res.activity.school); }
      this.res.etop3 = this.res.etop3.slice(0, 3);
      this.activitys = this.activitys.slice(0, 3);
      this.gflt_data = this.res.gflt.slice(0, 3);
      this.inauguration = this.res.inauguration;
      // this.inauguration.status = this.EDU_EXPO_LIVE_STATUS_LIVING;
      // this.inauguration.hls = 'http://cdn-lt-hls-vod.tv2fyn.dk/fhls/p/1966291/sp/196629100/serveFlavor/entryId/0_yct7rqn3/v/2/flavorId/0_h2wfmuqm/name/a.mp4/index.m3u8';
      if (this.inauguration.status == this.EDU_EXPO_LIVE_STATUS_LIVING) {
        setTimeout(() => {
          this.goplay();
        }, 1000);
      } else if (this.inauguration.status == this.EDU_EXPO_LIVE_STATUS_END) {
        setTimeout(() => {
          this.player = document.getElementById('endVideo');
        }, 1000);
      }
      this.loaded = true;
    });
  }

  gopage(url){
    this.router.navigateByUrl(url);
  }

  changeType(id) {
    this.select_type_id = id;
    if (this.player) { this.player.pause(); }
  }

  goplay(){
    const that = this;
    that.player = videojs(that.livevideo.nativeElement, {
      // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      controls: 'true',
      // 自动播放属性,muted:静音播放
      // autoplay: "true",
      // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      preload: 'auto',
      controlBar: { // 设置控制条组件
        /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
        children: [
          {name: 'playToggle'}, // 播放按钮
          {name: 'currentTimeDisplay'}, // 当前已播放时间
          {name: 'progressControl'}, // 播放进度条
          {name: 'durationDisplay'}, // 总时间
          {
            name: 'volumePanel', // 音量控制
            inline: false, // 不使用水平方式
          },
          {name: 'FullscreenToggle'} // 全屏
        ]
      }
    }, function onPlayerReady() {
      console.log('onPlayerReady', that);
      // that.player.play();
    });
      // };
  }
}
