<app-layout-main-2022 [navi]="8">
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px"/>
    <div class="title">历届集锦</div>
  </div>

  <div class="tabnav">
      <div class="nav">
        <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;历届集锦
      </div>
  </div>
  <div class="previous">
    <img src="//expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com/app/edu-expo/2022/image/wjjc.jpg" alt="" class="previous-img">
  </div>
</app-layout-main-2022>
