import { Component, OnInit, Input } from '@angular/core';

export interface ToastObj {
  title:     string,
  duration?: number
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.styl']
})
export class ToastComponent implements OnInit {

  @Input() toastObj: ToastObj

  constructor() { }

  ngOnInit() { 
    
  } 
  
}
