import { Injectable } from '@angular/core';   
import { DialogService } from 'src/app/dialog';
import { LoaddingComponent, ToastObj, ToastComponent, ModalComponent, ModalObj } from '../components';
  
@Injectable({ providedIn: 'root' })
export class EchoService {

    constructor(private dialogService: DialogService,
                ) { }
    
    showLoading() {
        this.dialogService.open(LoaddingComponent, {
            inputs:  {},
            outputs: {}
        })
    }

    hideLoading() {
        this.dialogService.close()
    }
 
    showToast(toastObj: ToastObj) {
        this.dialogService.open(ToastComponent, {
            inputs:  {toastObj},
            outputs: {}
        })
         
        
        let duration = toastObj.duration > 0 ? toastObj.duration : 1500
        setTimeout(() => {
            this.dialogService.close()
        }, duration);
    }

    showModal(modalObj: ModalObj) {  
        let  defaultObj = {
            title:      '',
            content:    '',
            showLeft:   true,
            leftText:   '取消',
            leftColor:  '#000000',
            rightText:  '确定',
            rightColor: '#576B95',
            bgcolor:     'rgba(0,0,0, 0.6)',
            leftFunc:     () => { },
            rightFunc:    () => { },
            completeFunc: () => {}  
        }

        for (const key in defaultObj) {
            if (modalObj.hasOwnProperty(key)) { 
                defaultObj[key] = modalObj[key]
            } 
        }

        this.dialogService.open(ModalComponent, { 
            inputs: { modalObj: defaultObj },
            outputs: { }, 
        })
    }
 
} 