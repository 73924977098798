import { Pipe, PipeTransform } from '@angular/core';  

// zh
const languageMap = {
    'en': {
        '启动中': 'Starting',
    }
} 

@Pipe({ name: 'appTf' }) 
export class TfPipe implements PipeTransform {
     
    constructor(  ) { }

    transform(value: any, ...args: any[]): any {  
        
        return value
    }
}
 