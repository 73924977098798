import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-studentactivity',
  templateUrl: './studentactivity.component.html',
  styleUrls: ['./studentactivity.component.styl']
})
export class StudentactivityComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router) { }

  rows: any;
  loaded = false

  ishow = false
  row: any = {}

  uroot = environment.U_ROOT_URL
  sroot = environment.S_ROOT_URL

  ngOnInit() {

    this.getdata();
  }

  getdata(){
    this.http.get('/pc/activity').subscribe((res) => {
      this.rows = res
      this.loaded = true

      // this.ishow = true
      // this.row   = this.rows[0]
    })
  }

  toview(i: number) {
    this.ishow = true
    this.row   = this.rows[i]
  }

}
