export const XROUTES = {
  2021: {
    index: {url: '/2021', title: '首页', ptitle: '首页 - 2021上海教育博览会'},
    lookguide: {url: '/2021/lookguide', title: '观展指南', ptitle: '观展指南 - 2021上海教育博览会'},
    onlinelook: {url: '/2021/onlinelook', title: '在线观展', ptitle: '在线观展 - 2021上海教育博览会'},
    reservation: {url: '/2021/reservation', title: '预约参观', ptitle: '预约参观 - 2021上海教育博览会'},
    featureguide: {url: '/2021/featureguide', title: '特色导览', ptitle: '特色导览 - 2021上海教育博览会'},
    onlinemap: {url: '/2021/onlinemap', title: '电子地图', ptitle: '电子地图 - 2021上海教育博览会'},
    edulive: {url: '/2021/edulive', title: '教育直播', ptitle: '教育直播 - 2021上海教育博览会'},
    redresources: {url: '/2021/redresources', title: '红色校园', ptitle: '红色校园 - 2021上海教育博览会'},
    news: {url: '/2021/news', title: '新闻发布', ptitle: '新闻发布 - 2021上海教育博览会'},
    studentactivity: {url: '/2021/studentactivity', title: '学生活动', ptitle: '学生活动 - 2021上海教育博览会'},
    hots: {url: '/2021/hots', title: '人气展位', ptitle: '人气展位 - 2021上海教育博览会'},
    vr: {url: '/2021/vr', title: 'VR体验', ptitle: 'VR体验 - 2021上海教育博览会'},
    previous: {url: '/2021/previous', title: '往届精粹', ptitle: '往届精粹 - 2021上海教育博览会'},
  },
  2022: {
    index: {url: '/2022-720', title: '云展览', ptitle: '云展览 - 2022上海教育博览会', blank: true},
    onlinemap: {url: '/2022/onlinemap', title: '云地图', ptitle: '云地图 - 2022上海教育博览会'},
    edulive: {url: '/2022/edulive', title: '云直播', ptitle: '云直播 - 2022上海教育博览会'},
    onlinelook: {url: '/2022/onlinelook', title: '云体验', ptitle: '云体验 - 2022上海教育博览会'},
    activety: {url: '/2022/activety', title: '云活动', ptitle: '云活动 - 2022上海教育博览会'},
    lookguide: {url: '/2022/lookguide', title: '观展指南', ptitle: '观展指南 - 2022上海教育博览会'},
    // hots: {url:'/2022/hots',title:'展会看板',ptitle:'展会看板 - 2022上海教育博览会'},
    hots: {url: '/report/2022', title: '展会看板', ptitle: '展会看板 - 2022上海教育博览会', blank: true},
    news: {url: '/2022/news', title: '新闻视点', ptitle: '新闻视点 - 2022上海教育博览会'},
    previous: {url: '/2022/previous', title: '历届集锦', ptitle: '历届集锦 - 2022上海教育博览会'},
  },
  2023: {
    index: {url: '/2023', title: '首页', ptitle: '首页 - 2023上海教育博览会'},
    onlineexhibition: {url: '/2023/onlineexhibition', title: '云展览', ptitle: '云展览 - 2023上海教育博览会'},
    onlinemap: {url: '/2023/onlinemap', title: '云地图', ptitle: '云地图 - 2023上海教育博览会'},
    edulive: {url: '/2023/edulive', title: '云直播', ptitle: '云直播 - 2023上海教育博览会'},
    onlinelook: {url: '/2023/onlinelook', title: '云体验', ptitle: '云体验 - 2023上海教育博览会'},
    lookguide: {url: '/2023/lookguide', title: '观展指南', ptitle: '观展指南 - 2023上海教育博览会'},
    news: {url: '/2023/news', title: '新闻视点', ptitle: '新闻视点 - 2023上海教育博览会'},
    report: {url: '/2023/report', title: '展会看板', ptitle: '展会看板 - 2023上海教育博览会'},
    reservation: {url: '/2023/reservation', title: '预约参观', ptitle: '预约参观 - 2023上海教育博览会'},
    previous: {url: '/2023/previous', title: '往届精粹', ptitle: '往届精粹 - 2023上海教育博览会'},
  },
  2024: {
    index: {url: '/2024', title: '首页', ptitle: '首页 - 2024上海教育博览会'},
    onlineexhibition: {url: '/2024/onlineexhibition', title: '云展览', ptitle: '云展览 - 2024上海教育博览会'},
    onlinemap: {url: '/2024/onlinemap', title: '云地图', ptitle: '云地图 - 2024上海教育博览会'},
    edulive: {url: '/2024/edulive', title: '云直播', ptitle: '云直播 - 2024上海教育博览会'},
    onlinelook: {url: '/2024/onlinelook', title: '科学教育', ptitle: '科学教育 - 2024上海教育博览会'},
    lookguide: {url: '/2024/lookguide', title: '观展指南', ptitle: '观展指南 - 2024上海教育博览会'},
    news: {url: '/2024/news', title: '新闻视点', ptitle: '新闻视点 - 2024上海教育博览会'},
    report: {url: '/2024/report', title: '展会看板', ptitle: '展会看板 - 2024上海教育博览会'},
    reservation: {url: '/2024/reservation', title: '预约参观', ptitle: '预约参观 - 2024上海教育博览会'},
    previous: {url: '/2024/previous', title: '往届精粹', ptitle: '往届精粹 - 2024上海教育博览会'},
  },
};
