import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-lookguide',
  templateUrl: './lookguide.component.html',
  styleUrls: ['./lookguide.component.styl']
})
export class LookguideComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }
  rows: any;
  loaded: any;
  osslink = '?x-oss-process=image/resize,w_300';

  S_ROOT_URL = environment.S_ROOT_URL

  ngOnInit() {
    this.getdata();
  }

  getdata(){
    this.http.get('/pc/guide').subscribe((res) => {
      this.rows = res;
      this.loaded = true
    })
  }

  gopdf(item){
    window.open(this.S_ROOT_URL + item.file);
 }

}
