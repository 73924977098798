import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-maintain',
  templateUrl: './maintain.component.html',
  styleUrls: ['./maintain.component.styl']
})
export class MaintainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
