<app-layout-main-2022 [navi]='7'>
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
    <div class="title">新闻视点</div>
  </div>
  <div class="tabnav">
    <div class="nav">
      <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;新闻视点
    </div>
  </div>
  <div class="news-wrapper gl-min-width">
    <div class="news-content gl-container-width">
        <div class="top-b">

        </div>
        <div class="news-bg">
          <img src="assets/imgs/9/news-bg.png"/>
        </div>
        <div class="news-container">
          <div class="news-list">
            <div class="news-list-row" *ngFor="let item of rows" (click)="toview(item)">
              <div class="nlr-dfimg">
                <img [src]="U_ROOT_URL + item.file + osslink"/>
              </div>
              <div class="infor-group">
                <div class="txt ttitle">{{item.name}}</div>
                <div class="txt time">{{item.created}}</div>
                <div class="txt time">{{item.author}}</div>
                <!-- <div class="txt intro">在10月21日举行的区域教育大专家直播——嘉善教育访谈中，嘉善县教育局党委书
                  记、局长朱利军，上海市教育委员会副主任倪闽景，上海教育电视台主持人徐丽遐
                  齐聚专家直播间，围绕“让教师流 .....</div> -->
                <div class="btns">
                  <div class="btn">
                    <div class="txt">查看全文</div>
                    <img src="assets/imgs/index/toright.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="news-recommand-list" style="background-image: url(assets/imgs/9/news-recommand-bg.png);">
            <div class="nrl-title" style="background-image: url(assets/imgs/9/news-recommand-title-bg.png);">相关新闻</div>
            <div class="nrl-rows">
              <div class="nrl-row" *ngFor="let item of [1,1,1,1,1,1,1,1,1,1,1]" (click)="toview()" >
                <img src="assets/imgs/9/news-df1.png" />
                <div class="ntitle">直播标题直播标题直播标题</div>
                <div class="ntime">开播时间：2021年06月07日 12:00 ～ 13:00</div>
                <div class="nintro">教育局领导谈未来教育方向，教育局领导谈未来教育方向，教育局领导谈未来教育方向，教育局领导谈未来教育方向</div>
              </div>
            </div>
          </div> -->
        </div>
    </div>
  </div>
</app-layout-main-2022>
