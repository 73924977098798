<app-layout-main-2022 [navi]='6' *ngIf="loaded">
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
    <div class="title">展会看板</div>
  </div>
  <div class="tabnav">
    <div class="nav">
      <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;展会看板
    </div>
  </div>

  <div class="hots-wrapper gl-min-width">
    <div class="hots-content gl-container-width">
        <div class="list-wrapper">
          <div class="lwgroup">
            <div class="type-rows">
              <div class="tr-name" [ngClass]="{'active': sindex == index}" *ngFor="let item of rows; let index = index" (click)="gotab(index)">{{item.name}}</div>
              <!-- <div class="tr-name active">总榜</div>
              <div class="tr-name">区教育局</div>
              <div class="tr-name">职业教育区</div>
              <div class="tr-name">高等教育区</div> -->
            </div>
            <div class="lwlist-group">
              <div class="header">
                <div class="lwth ">排名</div>
                <div class="lwth">名称</div>
                <div class="lwth">人气值</div>
              </div>
              <div class="body">
                <div class="lwtr" *ngFor="let item of rows[sindex].rows;let i=index">
                  <div class="lwtd">
                    <ng-container *ngIf="i<3">
                      <img [src]="'assets/imgs/11/top'+(i+1)+'.png'" />
                    </ng-container>
                    <ng-container *ngIf="i>2"> {{i+1}} </ng-container>
                  </div>
                  <div class="lwtd" [ngClass]="{'ibold':i<3}">{{item.name}}</div>
                  <div class="lwtd">{{item.pv}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="lw-bg" style="background-color: #3B83FF">
            <img src="/assets/2022/imgs/hots/bg@2x.png"/>
          </div>
        </div>
    </div>
  </div>
</app-layout-main-2022>
