import { Injectable,Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { tap, map, catchError, } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { EchoService } from "../../shared/services/echo.service";
import { UtilsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class NotificationInterceptor implements HttpInterceptor {

  constructor( private router: Router,
               private echo: EchoService,
               private injector: Injector,
               private utilService: UtilsService
              ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> >{

    return next.handle(req).pipe(
        map(evt => {
          if (evt instanceof HttpResponse && evt.body.data) evt = evt.clone({body: evt.body.data})

          return evt
        }),
        catchError((err: any) => {
            if(err instanceof HttpErrorResponse) {
              if(err.status != 0){
                this.echo.showToast(
                  {
                    title:JSON.stringify(err.error.message)
                  }
                )
              }
              if (err.status == 401) {
                    this.echo.showToast(
                      {
                        title:"您的登录信息已过期，请重新登录"
                      }
                    )
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("userinfo");

              }
            }

            return of(err);
        }),

      );

  }


}
