<app-layout-main *ngIf="loaded">
  <div class="banner-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/index/top-banner.png"/>
  </div>
  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/index/ticket.png"/>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/common/gap-bg.png"/>
  </div>

  <div class="news-wrapper gl-min-width">
    <div class="news-content gl-container-width">
      <div class="news-body">
        <div class="news-rows">
          <div class="news-row" *ngFor="let item of res.news">
            <div class="dfcover">
              <img  *ngIf="item.file" src="assets/imgs/index/news-defualt-img.png"/>
            </div>
            <div class="news-infor">
              <div class="news-title">{{item.name}}</div>
              <div class="news-date">{{item.created}}</div>
              <!-- <div class="news-intro">在10月21日举行的区域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈...</div> -->
              <div class="news-view" (click)="gopage('/2021/news/' + item.id)">
                <span>查看全文</span>
                <img src="assets/imgs/index/toright.png"/>
              </div>
            </div>
          </div>
        </div>
        <div class="news-btns">
          <div class="news-btn" (click)="gopage('/2021/news')">
            <img src="assets/imgs/index/news-btn.png"/>
          </div>
        </div>
      </div>
      <div class="news-bg">
        <img src="assets/imgs/index/news-bg.png"/>
      </div>
    </div>
  </div>

  <div class="gap-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/common/gap-bg.png"/>
  </div>

  <div class="hots-wrapper gl-min-width">
    <div class="hots-content gl-container-width">
      <div class="hots-body">

      </div>
      <div class="hots-bg">
        <img src="assets/imgs/index/hots-bg.png"/>
        <div class="list-btn">
          <div class="lbitem" *ngFor="let item of res.etop3">
            <div class="title">{{item.name}} </div>
            <div class="booth">展位：{{item.desktop}}</div>
            <div class="popular">人气值：{{item.praise}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/common/gap-bg.png"/>
  </div>

  <div class="online-wrapper gl-min-width">
    <div class="online-content gl-container-width">
      <div class="online-body">
        <div class="online-rows">
          <div class="online-row" *ngFor="let item of res.exhibitor" (click)="gopage('/2021/onlinelook/' + item.id)">
              <img *ngIf="item.file" [src]="U_ROOT_URL + item.file"/>
              <img *ngIf="!item.file" src="assets/imgs/index/online-df-bg.png"/>
            <div class="online-infor">
              <div class="oileft">
                <div class="name">{{item.name}}</div>
                <div class="number">展位：{{item.desktop}}</div>
              </div>
              <div class="oiright">
                <img src="assets/imgs/common/fire.png"/>
                <div>人气值：{{item.pv}}</div>
              </div>
            </div>
            <div class="online-row-bg">
              <img src="assets/imgs/index/online-intro-bg.png"/>
            </div>
          </div>
        </div>
      </div>
      <div class="online-bg">
        <img src="assets/imgs/index/online-bg.png"/>
      </div>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper online-bottom-gap">
    <img src="/assets/imgs/common/gap-bg2.png"/>
  </div>

  <div class="guide-wrapper gl-min-width">
    <div class="guide-content gl-container-width" (click)="gopage('/2021/featureguide')">
      <img src="assets/imgs/index/guide.png"/>
    </div>
    <div class="guide-wrapper-bg">
      <img src="assets/imgs/index/guide-bg.png"/>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/imgs/common/gap-bg3.png"/>
  </div>

  <div class="student-activity-wrapper gl-min-width">
    <div class="student-activity-content gl-container-width">
      <div class="student-activity-body">
        <div class="student-activity-rows">
          <ng-container *ngFor="let item of activitys;let i=index">
            <div class="student-activity-row" *ngIf="i<3" (click)="gopage('/2021/studentactivity')">
              <div class="dfcover">
                <img src="assets/imgs/index/student-activity-df-img.png"/>
              </div>
              <div class="student-activity-infor">
                <div class="student-activity-title">{{item.name}}</div>
                <div class="student-activity-date">{{item.showtime}}</div>
                <div class="student-activity-intro"[innerHTML]="item.description"></div>
                <div class="student-activity-view">
                  <span>了解更多</span>
                  <img src="assets/imgs/index/toright.png"/>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="student-activity-bg">
        <img src="assets/imgs/index/student-bg.png"/>
      </div>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper student-activity-gap">
    <img src="/assets/imgs/common/gap-bg4.png"/>
  </div>


  <div class="map-wrapper gl-min-width">
    <div class="map-content gl-container-width">
      <div class="map-body">

      </div>
      <div class="map-bg">
        <img (click)="gopage('/2021/onlinemap')" src="assets/imgs/index/map-intro.png"/>
      </div>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width imgs-wrapper map-gap">
    <img src="/assets/imgs/common/gap-bg5.png"/>
  </div>


  <div class="edu-wrapper gl-min-width">
    <div class="edu-content gl-container-width">
      <div class="edu-body">
        <div class="edu-video-group" (click)="gopage('/2021/edulive')">
          <video
              id="fullscreen-video"
              name="media"
              poster="assets/imgs/index/edu-video-demo.png" preload="auto"
              playsinline="true" webkit-playsinline="true" x5-video-player-type="h5-page" >
              <source type="video/mp4" src="https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/vod.linktour.com/431dbaede88d4dadab25ab2ff48e3405/ea937a0657294a1db78aaa0db6886ee8-ddf39bfb7ac7526476bc6e9e30a3a51b-sd.mp4" >
              <p>你的浏览器不支持 <code>Video</code> </p>
          </video>
          <div class="play-group">
            <img src="assets/imgs/common/i-play.png"/>
          </div>
        </div>
        <div class="edu-video-intro-group">
          <div class="title" *ngIf="res.live.livestatus"> 直播中... 去观看</div>
          <div class="title" *ngIf="res.live.msg"> 暂无直播-敬请期待 </div>
          <!-- <div class="intro"> 文案占位示意图 </div> -->
          <div class="bg"><img src="assets/imgs/index/edu-title-bg.png"/></div>
        </div>
      </div>
      <div class="edu-bg">
        <img src="assets/imgs/index/edu-bg.png"/>
      </div>
    </div>
  </div>

</app-layout-main>
