import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from '../app.guard';
import { IndexComponent } from './1_mainindex';
import { XROUTES } from '../shared/config';
import { OnlinemapComponent } from './2_onlinemap';
import { EduliveComponent } from './3_edulive';
import { OnlinelookComponent } from './4_onlinelook';
import { ActivetyComponent } from './5_activety';
import { LookguideComponent } from './6_lookguide';
import { Hots2022Component } from './7_hots';
import { News2022Component } from './8_news';
import { Previous2022Component } from './9_previous';
import { ActivetyTab3DetailComponent } from './5_activety/tab3/detail';
import { NewsDetailComponent } from './8_news/detail';

const routes: Routes = [
  {
    path: "",
    children: [
      { path: "", canActivate: [AppGuard], component: IndexComponent, pathMatch: "full", data: { title: '2022上海教育博览会' }},
      { path: "onlinemap", canActivate: [AppGuard], component: OnlinemapComponent, pathMatch: "full", data: { title: XROUTES[2022].onlinemap.title }},
      { path: "edulive", canActivate: [AppGuard], component: EduliveComponent, pathMatch: "full", data: { title: XROUTES[2022].edulive.title }},
      { path: "onlinelook", canActivate: [AppGuard], component: OnlinelookComponent, pathMatch: "full", data: { title: XROUTES[2022].onlinelook.title }},
      { path: "activety", canActivate: [AppGuard], component: ActivetyComponent, pathMatch: "full", data: { title: XROUTES[2022].activety.title }},
      { path: "activety/tab3/:id", canActivate: [AppGuard], component: ActivetyTab3DetailComponent, pathMatch: "full", data: { title: XROUTES[2022].activety.title }},
      { path: "lookguide", canActivate: [AppGuard], component: LookguideComponent, pathMatch: "full", data: { title: XROUTES[2022].lookguide.title }},
      { path: "news", canActivate: [AppGuard], component: News2022Component, pathMatch: "full", data: { title: XROUTES[2022].news.title }},
      { path: "news/:id", canActivate: [AppGuard], component: NewsDetailComponent, pathMatch: "full", data: { title: XROUTES[2022].news.title }},
      { path: "hots", canActivate: [AppGuard], component: Hots2022Component, pathMatch: "full", data: { title: XROUTES[2022].hots.title }},
      { path: "previous", canActivate: [AppGuard], component: Previous2022Component, pathMatch: "full", data: { title: XROUTES[2022].previous.title }},
  ]},
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class M2022RoutingModule { }
