<app-layout-main [navi]='5'>
  <div class="onlinelook-wrapper gl-min-width">
    <div class="onlinelook-content gl-container-width">
      <div class="title">
        <img src="assets/imgs/6/title.png"/>
      </div>
      <iframe src="https://edumap.shnu.edu.cn/start"></iframe>
    </div>
  </div>
</app-layout-main>
