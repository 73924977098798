import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import videojs from 'video.js'
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-activety-2022-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.styl']
})
export class ActivetyTab1Component implements OnInit {

  EDU_EXPO_LIVE_STATUS_WAITING = 1  //待播中
  EDU_EXPO_LIVE_STATUS_LIVING = 2   //直播中
  EDU_EXPO_LIVE_STATUS_PADDING = 3  //直播结束无视频
  EDU_EXPO_LIVE_STATUS_END = 4      //直播结束 有视频
  S_ROOT_URL = environment.S_ROOT_URL

  row:any = {
    status: this.EDU_EXPO_LIVE_STATUS_WAITING,
    hls: '',
  }

  player: videojs.Player;

  @ViewChild('livevideo') livevideo: ElementRef;
  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
   this.getData()
  }

  getData() {
    this.http.get('/pc/inauguration').subscribe((res: any) => {
        this.row = res;
        // this.row.status = this.EDU_EXPO_LIVE_STATUS_LIVING;
        if (this.row.status == this.EDU_EXPO_LIVE_STATUS_LIVING) {
          setTimeout(() => {
            this.goplay()
          }, 1000);
        }
     })
  }

  goplay(){
    const that = this;
    that.player = videojs(that.livevideo.nativeElement, {
      //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      controls: "true",
      //自动播放属性,muted:静音播放
      // autoplay: "true",
      //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      preload: "auto",
      controlBar: { // 设置控制条组件
        /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
        children: [
          {name: 'playToggle'}, // 播放按钮
          {name: 'currentTimeDisplay'}, // 当前已播放时间
          {name: 'progressControl'}, // 播放进度条
          {name: 'durationDisplay'}, // 总时间
          {
            name: 'volumePanel', // 音量控制
            inline: false, // 不使用水平方式
          },
          {name: 'FullscreenToggle'} // 全屏
        ]
      }
    }, function onPlayerReady() {
      console.log('onPlayerReady', that);
      // that.player.play();
    });
      // };
  }
  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
