import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { M2021RoutingModule } from './m2021-routing.module';

import { MainIndexComponent } from './1_mainindex/index';
import { LookguideComponent } from './2_lookguide';
import { OnlinelookComponent, OnlinelookDetailComponent } from './3_onlinelook';
import { ReservationComponent } from './4_reservation';
import { FeatureguideComponent } from './5_featureguide';
import { OnlinemapComponent } from './6_onlinemap';
import { EduliveComponent } from './7_edulive';
import { RedresourcesComponent } from './8_redresources';
import { NewsComponent, NewsDetailComponent } from './9_news';
import { StudentactivityComponent } from './10_studentactivity';
import { HotsComponent } from './11_hots';
import { VrComponent } from './12_vr';
import { PreviousComponent } from './13_previous';

@NgModule({
  declarations: [
    MainIndexComponent,
    LookguideComponent,
    OnlinelookComponent,
    OnlinelookDetailComponent,
    ReservationComponent,
    FeatureguideComponent,
    OnlinemapComponent,
    EduliveComponent,
    RedresourcesComponent,
    NewsComponent,
    NewsDetailComponent,
    StudentactivityComponent,
    HotsComponent,
    VrComponent,
    PreviousComponent
  ],
  imports: [
    M2021RoutingModule,
    SharedModule,
  ]
})
export class M2021Module { }
