import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-activety-2022-tab3',
  templateUrl: './tab3.component.html',
  styleUrls: ['./tab3.component.styl']
})
export class ActivetyTab3Component implements OnInit {
  
  select_type_id = 1

  activety_types = [
    {id: 1, name: '主题活动'},
    {id: 2, name: '校园风采'},
  ]

  show_modal = false
  row :any = {
    organizer: [],
    target: [],
  }

  res:any = {}
  data = []
  data_map = {}
  U_ROOT_URL = environment.U_ROOT_URL
  S_ROOT_URL = environment.S_ROOT_URL

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.getData()
  }

  changeType(id) {
    this.select_type_id = id
    this.initData();
  }

  initData() {
    this.data = this.select_type_id == 1 ? this.res.topic : this.res.school
  }

  goDetail(id) {
    this.router.navigateByUrl('/2022/activety/tab3/'+id)
  }

  getData() {
    this.http.get('/pc/activity2022?type='+this.select_type_id).subscribe((res: any) => {
      res.school.forEach(item => {
        this.data_map[item.id] = item;
      })
      res.topic.forEach(item => {
        this.data_map[item.id] = item;
      })
      this.res = res;
      this.initData();
     })
  }

  showModal(id) {
    this.show_modal = true
    this.row = this.data_map[id]
  }
}
