import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-activety-2022',
  templateUrl: './lookguide.component.html',
  styleUrls: ['./lookguide.component.styl']
})
export class LookguideComponent implements OnInit {

  nav_select_id = 2
  nav_select_title = '观展指南'

  navs = [
    {id: 2, title: '特色线路', img: 'navi_icon2'},
    {id: 1, title: '宣传手册', img: 'navi_icon1'},
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams.tab
    if (id) {
      this.nav_select_id = id
      this.nav_select_title = this.navs[id - 1].title
    }
  }

  goTab(item) {
    this.router.navigateByUrl('/2022/lookguide?tab='+item.id)
    this.nav_select_id = item.id
    this.nav_select_title = item.title
  }
}
