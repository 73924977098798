import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-onlinelook-2022-tab5',
  templateUrl: './tab5.component.html',
  styleUrls: ['./tab5.component.styl']
})
export class OnlinelookTab5Component implements OnInit {


  data = []

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.http.get('/pc/vr').subscribe((res: any) => {
        this.data = res
     })
  }

  goView(url) {
    if (url) window.open(url, '_blank');
  }

}
