import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';
import { ErrorComponent } from './shared/components';
import { MaintainComponent } from './shared/components/maintain';

const routes: Routes = [
  { path: '2021', loadChildren: () => import('./m2021/m2021.module').then(m => m.M2021Module) },
  { path: '2022', loadChildren: () => import('./m2022/m2022.module').then(m => m.M2022Module) },
  { path: '2023', loadChildren: () => import('./m2023/m2023.module').then(m => m.M2023Module) },
  { path: '2024', loadChildren: () => import('./m2024/m2024.module').then(m => m.M2024Module) },
  { path: 'report', loadChildren: () => import('./echarts/echarts.module').then(m => m.EchartsModule) },
  { path: 'error', component: ErrorComponent, pathMatch: 'full', data: { title: '页面走丢了 - 上海教博会' } },
  { path: 'maintain', component: MaintainComponent, pathMatch: 'full', data: { title: '维护中 - 上海教博会' } },
  { path: '', canActivate: [AppGuard], redirectTo: '/2024', pathMatch: 'full', data: { title: '上海教育博览会' } },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top', // 跳转页面时默认滚轴在顶部 onlineexhibition
      })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
