import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-onlinelook-2022-tab2',
  templateUrl: './tab2.component.html',
  styleUrls: ['./tab2.component.styl']
})
export class OnlinelookTab2Component implements OnInit {

  data = []

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.http.get('/pc/jyssb').subscribe((res: any) => {
        this.data = res
     })
  }

}
