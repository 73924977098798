<app-layout-main-2022 [navi]="2">
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
    <div class="title">云直播</div>
  </div>
  <div class="tabnav">
    <div class="nav">
      <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;云直播
    </div>
  </div>
  <div class="content-banner">
      <div class="content-title">
        <img src="/assets/2022/imgs/edulive/calendar_icon@2x.png" alt="" width="48px" height="48px">
        <span class="title-content">教育大直播 2022年9～10月 直播行程</span>
      </div>
      <div class="calendar">
        <div>
          <div class="month-title"><span class="month">09</span>月</div>
          <img src="/assets/2022/imgs/edulive/calendar01@2x.jpg" alt="" class="cimg">
        </div>
        <div>
          <div class="month-title"><span class="month">10</span>月</div>
          <img src="/assets/2022/imgs/edulive/calendar02@2x.jpg" alt="" class="cimg" style="opacity: .8;">
        </div>
        <div class="bg4">
          <img src="/assets/2022/imgs/bg4@2x.jpg" width="666px" height="666px"/>
        </div>
      </div>
      <div class="live-pic">
        <img src="/assets/2022/imgs/edulive/live_pic1@2x.jpg" alt="" height="320px" width="450px">
      </div>
      <!-- 教育新闻坊 -->
      <div class="content-title">
        <img src="/assets/2022/imgs/edulive/calendar_icon@2x.png" alt="" width="48px" height="48px">
        <span class="title-content">教育新闻坊</span>
      </div>
      <div class="edunews-list">
          <div class="item" *ngFor="let item of edunews;let index=index" (click)="showModal(index)">
            <img [src]="U_ROOT_URL + item.poster + '?x-oss-process=image/resize,w_400'" alt="" width="100%">
            <div class="subject"> 
              <div> {{item.subject}}</div>
              <div> {{item.school_name}}</div>
            </div>
          </div>
      </div>
  </div>
  <div class="bg-right">
    <img src="/assets/2022/imgs/bg3@2x.jpg" width="410px" height="328px"/>
  </div>



<div class="popup-wrapper" *ngIf="show_modal && row.vlog" (click)="this.show_modal = false">
  <div class="popup-body" (click)="noC($event)">
     <div class="close" (click)="this.show_modal = false">✖️</div>
     <div class="modal" >
        <video
           width="100%"
           height="100%"
           [src]="row.vlog"
           name="media-2"
           preload="auto"
           playsinline="true" 
           webkit-playsinline="true" 
           x5-video-player-type="h5-page" 
           controls
           playsinline="true" >
           <p>你的浏览器不支持 <code>Video</code> </p>
        </video>
     </div>
  </div>
</div>
</app-layout-main-2022>
