import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-activety-2022-tab3-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class ActivetyTab3DetailComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  row = {
    tag: '中小学生',
    author: '上海教育报刊总社、上海市学生德育发展中心、上海人民广播电台',
    title: '听00后讲“四史”——小主播 大声音|第二届上海市中小学校园主播风采展示活动',
    content: `市南中学(原清心中学)建于1860年(清咸丰十年)，由娄离华学堂、清心书院、清心中学延续至今已经有一个半世纪的历史。学校具有悠久的革命传统。1937年7月，上海党组织领导的上海学生界救亡协会发动反日伪护校斗争，打响了上海中学抗日救亡运动的第一炮，市南中学就以“全福读书会”的名义积极参与斗争。几十年来，该校培养出了一大批优秀人才，代表人物有诺贝尔奖获得者李政道、著名脑外科专家史玉良、著名学者李昌道、漫画家丁聪等。市南中学校史室于2001年6月被黄浦区人民政府公布为黄浦区爱国主义教育基地。

    市南中学(原清心中学)建于1860年(清咸丰十年)，由娄离华学堂、清心书院、清心中学延续至今已经有一个半世纪的历史。学校具有悠久的革命传统。1937年7月，上海党组织领导的上海学生界救亡协会发动反日伪护校斗争，打响了上海中学抗日救亡运动的第一炮，市南中学就以“全福读书会”的名义积极参与斗争。几十年来，该校培养出了一大批优秀人才，代表人物有诺贝尔奖获得者李政道、著名脑外科专家史玉良、著名学者李昌道、漫画家丁聪等。`,
    imgs: [
      'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic_source%2Fe5%2F42%2Ff0%2Fe542f0b7969a01e037138624b082174d.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663496049&t=348de91d42415fde8783eb45c2d33450',
      'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F042421135351%2F210424135351-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663496078&t=a25ae4ff635a26d90e82b1524ed01424'
    ],
  }

  ngOnInit() {
      
  }

}
