import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-activety-2022',
  templateUrl: './activety.component.html',
  styleUrls: ['./activety.component.styl']
})
export class ActivetyComponent implements OnInit {

  nav_select_id = 1
  nav_select_title = '开幕式'

  navs = [
    {id: 1, title: '开幕式', img: 'act_icon1'},
    {id: 2, title: '高峰论坛', img: 'act_icon2'},
    {id: 3, title: '校园文化活动', img: 'act_icon3'},
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams.tab
    if (id) {
      this.nav_select_id = id
      this.nav_select_title = this.navs[id - 1].title
    }
  }

  goTab(item) {
    this.router.navigateByUrl('/2022/activety?tab='+item.id)
    this.nav_select_id = item.id
    this.nav_select_title = item.title
  }
}
