<app-layout-main-2022 [navi]="4">
   <div class="banner-wrapper gl-min-width">
     <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
     <div class="title">校园文化活动</div>
   </div>
   <div class="tabnav">
     <div class="nav">
       <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;云活动&ensp;&ensp;/&ensp;&ensp;校园文化活动&ensp;&ensp;/&ensp;&ensp;正文
     </div>
   </div>

   <div class="contains">
      <div class="tag">{{row.tag}}</div>
      <div class="author">{{row.author}}</div>
      <div class="title">{{row.title}}</div>
      <div class="content">{{row.content}}</div>
      <div class="content-img">
         <img [src]="img" alt="" *ngFor="let img of row.imgs">
      </div>
   </div>

   <img src="assets/2022/imgs/bg4@2x.jpg" alt="" class="bg1">
   <img src="assets/2022/imgs/bg3@2x.jpg" alt="" class="bg2">
 </app-layout-main-2022>
 