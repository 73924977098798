import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featureguide',
  templateUrl: './featureguide.component.html',
  styleUrls: ['./featureguide.component.styl']
})
export class FeatureguideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
