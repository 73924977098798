<app-layout-main [navi]='11' *ngIf="loaded">
  <div class="vr-wrapper gl-min-width">
    <div class="vr-content gl-container-width">
        <div class="ptitle"><img src="assets/imgs/12/title.png"/></div>

        <div class="vr-list-group">
          <div class="vr-item" *ngFor="let item of rows" (click)="govr(item)">
            <img [src]="US_ROOT_URL + '/app/edu-expo/cover/' + item.cover + osslink"/>
            <div class="infors" style="background-image: url('assets/imgs/12/vr-item-bg.png');">
              <div class="txt ititle">{{item.name}}</div>
              <div class="txt">{{item.description}}</div>
              <!-- <div class="txt">地址：上海XX区XX街道56号</div>
              <div class="txt">开放时间：上海XX区XX街道56号</div> -->
            </div>
          </div>
        </div>

        <div class="vr-bg">
          <img src="assets/imgs/12/vr-bg.png"/>
        </div>
    </div>
  </div>
</app-layout-main>
