import {Component, Input, OnInit} from '@angular/core';
import {XROUTES} from 'src/app/shared/config';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-layout-main-2024',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class LayoutMain2024Component implements OnInit {

  @Input() navi = 0;

  S_ROOT_URL = environment.S_ROOT_URL;

  navs = [
    XROUTES[2024].index,
    XROUTES[2024].onlineexhibition,
    XROUTES[2024].onlinemap,
    XROUTES[2024].edulive,
    XROUTES[2024].onlinelook,
    XROUTES[2024].lookguide,
    XROUTES[2024].news,
    XROUTES[2024].report,
    XROUTES[2024].reservation,
    XROUTES[2024].previous
  ];

  footernavs = [
    [XROUTES[2024].onlineexhibition, XROUTES[2024].onlinelook, XROUTES[2024].report],
    [XROUTES[2024].onlinemap, XROUTES[2024].lookguide, XROUTES[2024].reservation],
    [XROUTES[2024].edulive, XROUTES[2024].news, XROUTES[2024].previous],
  ];

  email = 'shjiaobohui@163.com';

  constructor() {
  }

  ngOnInit() {

  }

}
