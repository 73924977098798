import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CacheService } from '../services/cache.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: CacheService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const protocol = document.location.protocol || 'http:';
    const newReq = req.clone({
      url: protocol + environment.host + req.url,
      headers: req.headers
        .set('Content-Type', 'application/json')
        .set('Accept-Language', 'zh')
    });

    return next.handle(newReq);
  }
}
