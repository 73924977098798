import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-onlinelook-2022',
  templateUrl: './onlinelook.component.html',
  styleUrls: ['./onlinelook.component.styl']
})
export class OnlinelookComponent implements OnInit {

  nav_select_id = 1
  nav_select_title = '教育应用场景'

  navs = [
    {id: 1, title: '教育应用场景', 'img': 'experience_icon1'},
    {id: 6, title: '数字化小课堂', 'img': 'experience_icon6'},
    {id: 2, title: '教育“随申办”', 'img': 'experience_icon2'},
    {id: 3, title: '金色学堂', 'img': 'experience_icon3'},
    {id: 4, title: '在线人文行走', 'img': 'experience_icon4'},
    {id: 5, title: '市民终身学习在线体验基地', 'img': 'experience_icon5'},
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.queryParams.tab
    if (id) {
      this.nav_select_id = id
      this.nav_select_title = this.navs[id - 1].title
    }
  }

  goTab(item) {
    this.router.navigateByUrl('/2022/onlinelook?tab='+item.id)
    this.nav_select_id = item.id
    this.nav_select_title = item.title
  }
}
