import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-onlinelook',
  templateUrl: './onlinelook.component.html',
  styleUrls: ['./onlinelook.component.styl']
})
export class OnlinelookComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router) { }

  res: any;
  loaded = false
  Objectkeys = Object.keys
  sindex = 0
  U_ROOT_URL = environment.U_ROOT_URL
  osslink = '?x-oss-process=image/resize,w_500';

  ngOnInit() {
    this.getdata();
  }


  getdata(){
    this.http.get('/pc/vlog').subscribe((res) => {
      this.res = res;
      this.loaded = true
    })
  }

  toview(item) {
    this.router.navigateByUrl('/2021/onlinelook/' + item.id)
  }

  gotab(index){
    this.sindex = index
  }
}
