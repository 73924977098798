<app-layout-main [navi]='4'>
  <div class="onlinelook-wrapper gl-min-width">
    <div class="onlinelook-content gl-container-width">
      <div class="ptitle"><img src="assets/imgs/5/title.png"/></div>
      <div class="routes">
        <img src="assets/imgs/index/guide.png"/>
      </div>
      <div class="qrcode">
        <img src="assets/imgs/5/qrcode.png"/>
      </div>
    </div>
  </div>
</app-layout-main>