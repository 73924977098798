import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-onlinelook-2022-tab3',
  templateUrl: './tab3.component.html',
  styleUrls: ['./tab3.component.styl']
})
export class OnlinelookTab3Component implements OnInit {

  @ViewChild("goldSchoolVideo") goldSchoolVideo

  is_play = false

  no_video_modal = false

  video_modal = false

  select_idx = 0
  select_childs = []

  S_ROOT_URL = environment.S_ROOT_URL

  res:any = {}

  row:any = {}

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.getData()
  }

  play() {
    this.is_play = true
    const el = this.goldSchoolVideo.nativeElement;
    el.controls = true
    el.play();
  }

  changeTag(index) {
    this.select_idx = index
    this.select_childs = this.res.rows[this.select_idx].childs
  }


  getData() {
    this.http.get('/pc/jsxt').subscribe((res: any) => {
        this.res = res
        this.changeTag(0)
     })
  }

  childClick(row) {
    this.row = row
    if (row.url) this.video_modal = true
    else this.no_video_modal = true
  }

  closeModal() {
    this.no_video_modal = this.video_modal = false
  }

  noC(e) {
    e.stopPropagation();
    return false;
  }

}
