import { Component, Input, OnInit } from '@angular/core';
import { XROUTES } from 'src/app/shared/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-main-2022',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class LayoutMain2022Component implements OnInit {

  @Input() navi = 0;

  S_ROOT_URL = environment.S_ROOT_URL;

  navs = [
    XROUTES[2022].index,
    XROUTES[2022].onlinemap,
    XROUTES[2022].edulive,
    XROUTES[2022].onlinelook,
    XROUTES[2022].activety,
    XROUTES[2022].lookguide,
    XROUTES[2022].hots,
    XROUTES[2022].news,
    XROUTES[2022].previous
  ];

  footernavs = [
    [ XROUTES[2022].index, XROUTES[2022].lookguide, XROUTES[2022].onlinemap],
    [ XROUTES[2022].edulive, XROUTES[2022].onlinelook, XROUTES[2022].activety],
    [ XROUTES[2022].hots, XROUTES[2022].news, XROUTES[2022].previous],
  ];

  email = 'shjiaobohui@163.com';

  constructor() { }a

  ngOnInit() {

  }

}
