<div style="margin-bottom: 120px">
   <img *ngIf="row.status == EDU_EXPO_LIVE_STATUS_WAITING" [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/activity_kv@2x.jpg?x-oss-process=image/resize,w_862'" alt="" width="862px" height="452px">
   <video
      *ngIf="row.status == EDU_EXPO_LIVE_STATUS_LIVING && row.hls"
      #livevideo
      controls
      class="video-js vjs-default-skin vjs-big-play-centered"
      width="862px" 
      height="452px"
      data-setup='{"liveui": true}'
      disablePictureInPicture
   >
   <source [src]="row.hls" type="application/x-mpegURL">
   <p>你的浏览器不支持 <code>Video</code> </p>
   </video>
   <video *ngIf="row.status == EDU_EXPO_LIVE_STATUS_END || row.status == EDU_EXPO_LIVE_STATUS_PADDING"
      class="video"
      controls
      width="862px" 
      height="452px"
      disablePictureInPicture
   >
     <source [src]="row.hls" type="video/mp4">
     <p>你的浏览器不支持 <code>Video</code> </p>
   </video>
</div>