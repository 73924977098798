<div class="tv">
   <img src="assets/2022/imgs/onlinelook/tv@2x.png" alt="">
   <div class="desc">
      为了让老年人享有精准、智慧、适需的教育服务，<br/>上海市教育委员会、上海广播电视台（smg）再次联手，
      共同推出面向50+人群的全媒体终身教育平台<span class="specil-content">“金色学堂”</span>。
      <br>
      <br>
      即日起，<br/>
      <span class="specil-content">百视tv app中的“金色学堂”</span>模式率先上线啦！
      <br>
      这个春节，老年朋友可以在手机和平板上免费学习。
   </div>
   <video
      #goldSchoolVideo
      [src]="res.video"
      id="gold-school"
      name="media"
      preload="auto"
      playsinline="true" 
      webkit-playsinline="true" 
      x5-video-player-type="h5-page" 
      [poster]="S_ROOT_URL + res.poster"
      playsinline="true" >
      <p>你的浏览器不支持 <code>Video</code> </p>
   </video>
   <img src="/assets/2022/imgs/onlinelook/play@2x.png" alt="" class="play" (click)="play()" *ngIf="!is_play && res.video">
</div>
<div class="dashed"></div>
<div class="tags">
   <div class="tag" *ngFor="let tag of res.rows;let i=index" (click)="changeTag(i)">
       <img [src]="'assets/2022/imgs/onlinelook/experience_btn' + (i == select_idx ? '_active' : '') + '@2x.png'" alt="">
       <span class="tagname" [ngClass]="i == select_idx ? 'active' : ''" >{{tag.name}}</span>
   </div>
</div>
<div class="tag-hr"></div>
<div class="row" *ngFor="let row of select_childs">
   <div class="rowname" (click)="childClick(row)">{{row.name}}</div> &nbsp;&nbsp;<span *ngIf="row.url"> <img src="assets/2022/imgs/onlinelook/experience_playicon@2x.png" alt=""> </span>
</div>
<div class="footer-img">
   <img src="assets/2022/imgs/onlinelook/experience_pic1@2x.jpg" alt="">
</div>


<div class="popup-wrapper" *ngIf="no_video_modal || video_modal" (click)="closeModal()">
   <div class="popup-body" (click)="noC($event)">
      <div class="close" (click)="closeModal()">✖️</div>
      <div class="modal" [ngClass]="video_modal ? 'video' : ''">
         <div class="content" *ngIf="no_video_modal">
            请前往 <span class="baish">百视TV APP / 电视频道</span><br>
            查看精彩内容
            <div class="close-btn" (click)="closeModal()">确定</div>
         </div>
         <video
            *ngIf="video_modal"
            width="100%"
            height="100%"
            [src]="row.url"
            name="media-2"
            preload="auto"
            playsinline="true" 
            webkit-playsinline="true" 
            x5-video-player-type="h5-page" 
            controls
            playsinline="true" >
            <p>你的浏览器不支持 <code>Video</code> </p>
         </video>
      </div>
   </div>
 </div>