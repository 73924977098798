import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-onlinelook-2022-tab6',
  templateUrl: './tab6.component.html',
  styleUrls: ['./tab6.component.styl']
})
export class OnlinelookTab6Component implements OnInit {
  
  show_modal = false
  row :any = {
    organizer: []
  }

  data = []
  S_ROOT_URL = environment.S_ROOT_URL

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.http.get('/pc/szhxkt').subscribe((res: any) => {
      this.data = res
     })
  }

  showModal(id) {
    this.show_modal = true
    this.row = this.data[id]
  }
}
