import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanDeactivate,
  CanLoad
} from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { RunService, CacheService } from './shared/services';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanLoad,CanActivate, CanActivateChild, CanDeactivate<any> {

  constructor(
    private router: Router,
    private runService: RunService,
    private cacheService: CacheService, 
  ) { }

  canLoad(route: Route) {

    return true
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const url = state.url

    if (url.indexOf('/start') > -1) {
      // console.log(url)
      return true
    }

    this.runService.appStartStatus = true
    // if (!this.runService.appStartStatus && route.queryParams.r === 'miniprogram') this.runService.appStartStatus = true

    if (!this.runService.appStartStatus) {
      this.cacheService.set('rUrl', encodeURIComponent(url))
      this.router.navigate(['/start'], { replaceUrl: true })
    }
 
    return true
  }

  canActivateChild() {

    return true
  }

  canDeactivate(
    component:    any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState:    RouterStateSnapshot) {

    return true
  }
}
