<div class="header-wrapper gl-min-width" style="background: #fff">
  <img class="header-bg" src="/assets/2023/imgs/header_bg@2x.png" alt="" width="100%" />

  <div class="header-content gl-container-width">
    <div class="logo-group">
      <a href="/2023">
        <img class="logo1" src="assets/2023/imgs/logo20@2x.png" width="292px" height="60px" />
      </a>
    </div>
    <div class="navs-group">
      <div class="nav-item" *ngFor="let item of navs; let i = index;">
        <div class="nav-t" [ngClass]="{ 'active': i === navi }">
          <a [href]="item.url" [target]="item.blank ? '_blank' : ''" [ngStyle]="{ 'color': i === navi ? '#00549F' : '#5F7991' }">
            {{item.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="body-wrapper">
  <ng-content></ng-content>
</div>
<div class="footer-wrapper gl-min-width">
  <div class="footer-container">
    <div class="footer-main">
      <div class="footer-logo">
        <a href="/2023">
          <img src="/assets/2023/imgs/logo1_footer@2x.png" alt="" width="182px" height="58px">
        </a>
      </div>
      <div class="footer-content">
          <div class="content">
            <div class="title">
              <div class="title-point"></div>网站导航
            </div>
            <div class="footer-nav" *ngFor="let navs of footernavs;">
              <div *ngFor="let item of navs;let last = last" [style]="!last ? 'width:180px' : ''">
                <a [href]="item.url" class="footer-a">{{item.title}}</a>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="title">
              <div class="title-point"></div>联系我们
            </div>
            <div class="footer-nav contact-us">
              <div>邮箱：{{email}}</div>

              <div class="code">
                <div style="text-align:center">
                  <img src="/assets/2023/imgs/code1@2x.jpg" alt="" width="136px" height="136px">
                  <p>微信公众号</p>
                </div>
                <div style="text-align:center">
                  <img src="/assets/2023/imgs/code2@2x.jpg" alt="" width="136px" height="136px">
                  <p>扫码小程序观看教博会</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="footer-bg">
    <img src="/assets/2023/imgs/footer_bg@2x.jpg" height="482px" width="100%"/>
  </div>
  <div class="footer-beian">
    ©上海教育博览会 &nbsp;&nbsp; <a href=" https://beian.miit.gov.cn/" target="_blank">沪ICP备05052062号-1</a> &nbsp;&nbsp; <img src="/assets/2023/imgs/footer-icon2@2x.png"/> 沪公网安备：31009102000050号
  </div>
</div>

