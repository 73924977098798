import { Component, ViewChild } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
 
  @ViewChild('esImg') esImg

  constructor(titleService: Title, router: Router, private platformLocation: PlatformLocation) {
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join('-')
        titleService.setTitle(title)
        this.track()
      }
    })
  } 

  getTitle(state: any, parent: any) {
    const data = []
    if(parent && parent.snapshot.data && parent.snapshot.data.title) data.push(parent.snapshot.data.title) 

    if(state && parent) data.push(... this.getTitle(state, state.firstChild(parent)))
      
    return data
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }

  track() {
    let uvid = localStorage.getItem('uvid')
    if (!uvid) {
      uvid =  uuid.v4();
      localStorage.setItem('uvid', uvid)
    }
    const data = {
      uid: 0, 
      // scene: 0,
      // scene_id: 0,
      uv_id: uvid, //唯一标识
      device: 'pc',
      timestamp: Date.now(),
      host: this.platformLocation.hostname,
      port: this.platformLocation.port,
      protocol: this.platformLocation.protocol,
      uri: this.platformLocation.pathname,
      search: this.platformLocation.search,
      url: this.platformLocation.href,
    }
    let params = new URLSearchParams();
    for (let key in data) params.set(key, data[key])
    let tracker_index = environment.TRACKER_INDEX
    this.esImg.nativeElement.src = 'https://tracker99.cn-shanghai.log.aliyuncs.com/logstores/'+tracker_index+'/track_ua.gif?APIVersion=0.6.0&'+params.toString();
  }
}
