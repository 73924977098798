import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hots',
  templateUrl: './hots.component.html',
  styleUrls: ['./hots.component.styl']
})
export class HotsComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  rows: any;
  loaded = false
  sindex = 0

  ngOnInit() {
    this.getdata();
  }

  getdata(){
    this.http.get('/pc/vlog/rank').subscribe((res) => {
      this.rows = res
      this.loaded = true
    })
  }

  gotab(index){
    this.sindex = index
  }
}
