import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class NewsDetailComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute) { }

  loaded = false
  row: any
  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id')
    this.getdata(id)
  }

  getdata(id){
    this.http.get('/pc/news/' + id).subscribe((res) => {
      this.row = res
      this.loaded = true
    })
  }

}
