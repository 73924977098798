import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vr',
  templateUrl: './vr.component.html',
  styleUrls: ['./vr.component.styl']
})
export class VrComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  US_ROOT_URL = environment.US_ROOT_URL;
  osslink = '?x-oss-process=image/resize,w_600';

  rows: any;
  loaded = false

  ngOnInit() {
    this.getdata();
  }

  getdata(){
    this.http.get('/pc/vr').subscribe((res) => {
      this.rows = res
      this.loaded = true
    })
  }

  govr(item){
    // console.log(item);
    window.open(item.url);
  }

}
