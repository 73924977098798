import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as echarts from 'echarts';

@Component({
  selector: 'app-echarts-index-2022',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.styl']
})
export class IndexComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  pos = 435 //定位计算高度

  uv_arr = []

  scenes = [
    'map', 'eduscene', 'exhibitor',
    'uv',
    'live', 'xywh', 'jbdr'
  ]

  progress_keys = ['inauguration_pv', 'gflt_pv', 'live_pv', 'edunews_pv']

  nums = [0, 1, 2, 3, 4, 5, 6] //最多展示8位数

  middle_max_pv:any = 0

  echart:any = {}

  timestamp = Date.now();

  dates = {
    date: '',
    time: '',
    week: '',
    date_suf: '',
  }

  chartData = {
    map: {
      names: [],
      data: [],
      v_filed: 'pv',
    },
    eduscene: {
      names: [],
      data: [],
      v_filed: 'pv',
    },
    exhibitor: {
      names: [],
      data: [],
      v_filed: 'pv',
    },
    live: {
      names: [],
      data: [],
      v_filed: 'pv',
    },
    xywh: {
      names: [],
      data: [],
      v_filed: 'pv',
    },
    jbdr: {
      names: [],
      data: [],
      v_filed: 'balance',
    },
    inauguration_pv: 0,
    gflt_pv: 0,
    live_pv: 0,
    edunews_pv: 0,
    uv: 0,
  }

  mapData = {
    1: 0, //主题教育地图
    2: 0, //电子地图覆盖学校及机构
    3: 0, //电子地图点位
  }

  ngOnInit() {
    document.oncontextmenu =function () {return false; };
    this.setDate()
    this.getData()
    setInterval(() => {
      this.getData()
    }, 2500)
  }

  getData() {
    this.http.get('/data/top?limit=5').subscribe((res:any) => {
      res.timestamp *= 1000
      if (res.timestamp - this.timestamp > 5000) this.timestamp = res.timestamp;
      Object.keys(this.mapData).forEach(_i => {
        this.mapData[_i] =  res.static.map.rows[_i].num;
      })
      Object.keys(this.chartData).forEach(key => {
        let rk = key == 'exhibitor' ? 'exhibitor_chart' : key
        if (res.dynamic[rk]) {
          if (typeof this.chartData[key] === 'number') this.chartData[key] = res.dynamic[rk].num
          else {
            this.chartData[key].names = this.array_column(res.dynamic[rk].rows, 'name')
            this.chartData[key].data = this.array_column(res.dynamic[rk].rows, this.chartData[key].v_filed)
          }
        }
      })
      this.initEchart()
    })
  }

  initEchart() {
    this.middle_max_pv = (Math.max(this.chartData.inauguration_pv, this.chartData.gflt_pv, this.chartData.live_pv, this.chartData.edunews_pv) / 0.7).toFixed(0)
    this.initEchartUv()
    this.scenes.forEach(scene => {
      this['initEchart'+ scene.substring(0, 1).toUpperCase() + scene.substring(1)]();
    })
    this.progress_keys.forEach(progress_key => {
      this.initEchartProgress(progress_key);
    })
  }

  setDate() {
    const weekmap = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五']
    let minesecond = 1000
    setInterval(() => {
      const date = new Date(this.timestamp)
      let month:any = date.getMonth() + 1;
      let day:any = date.getDate();
      if (month < 10) month = '0' + month;
      if (day < 10) day = '0' + day;
      this.dates.date = date.getFullYear() + '-' + month + '-' + day;
      this.dates.time = date.toLocaleTimeString()
      this.dates.week = weekmap[date.getDay()]
      this.dates.date_suf = month + '月' + day + '日'
      this.timestamp += minesecond
    }, minesecond)
  }

  num2arr(num) {
    let sNumber = num.toString()
    let repeat0 = this.nums.length - sNumber.length;
    if (repeat0 > 0) for(let i = 0; i < repeat0; i++) sNumber = '0' + sNumber
    return sNumber.split('');
  }


  ngAfterViewInit() {
    const scenes = [
      'map', 'eduscene', 'exhibitor',
      'uv',
      'live', 'xywh', 'jbdr'
    ];
    scenes.forEach(scene => {
      let el = document.getElementById(scene) as HTMLDivElement
      if (scene == 'exhibitor') this.pos = el.clientWidth - 485 + 435
      this.echart[scene] = echarts.init(el)
    })
    this.progress_keys.forEach(progress_key => {
      let el = document.getElementById(progress_key) as HTMLDivElement
      this.echart[progress_key] = echarts.init(el)
    })
  }

  initEchartMap() {
    let option = {
      textStyle: {
        align:'right',
        color: '#8493B9',
        fontFamily: 'Avenir Black Oblique'
      },
      grid: {
        left: '1%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      xAxis: {
        axisTick: {show: false },
        type: 'value',
        splitLine: { show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#5C86CF', 
            width: 2, 
          },
        },
      },
      yAxis: {
        inverse: true,
        axisTick: {show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#559AE8', 
            width: 2, 
          },
        },
        type: 'category',
        data: this.chartData.map.names,
        axisLabel: {
          fontSize: '12',
          interval: 0, 
          formatter: function (params) {
            let newParamsName = '';
            const paramsNameNumber = params.length; // 文字总长度
            const provideNumber = 6; //一行显示几个字
            const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (let p = 0; p < rowNumber; p++) {
                const start = p * provideNumber;
                const end = start + provideNumber;
                const tempStr = p === rowNumber - 1 ? params.substring(start, paramsNameNumber) : params.substring(start, end) + '\n';
                newParamsName += tempStr;
                if (newParamsName.length > 22) {
                  newParamsName = newParamsName.substring(0, 19) + '...'
                  break
                }
              }
            } else {
              newParamsName = params;
            }
            return newParamsName;
          },
        },
        splitArea: {
          show: true,
          interval:0,
          areaStyle: {
              color: ['#0D2259', '#142966']
          }
        },
      },
      series: [
        {
          data:this.chartData.map.data,
          type: 'bar',
          showBackground: false,
          areaStyle: {
            color: ['#f3f2f2', '#eceaea'],
          },
          itemStyle : { 
            emphasis: {
              barBorderRadius:[0, 10, 10, 0]
            },
            normal: {
              barBorderRadius: [0, 10, 10, 0],
              label : {show: true, position: 'insideRight', textStyle:{color:'#0A1139', fontWeight: 800, fontSize: '14px'}},
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {offset: 0, color: '#0063FA'},
                {offset: 1, color: '#2AD0FF'},
              ])
            }
          },
          barWidth : 20
        }
      ]
    };
    this.echart.map.setOption(option);
  }

  initEchartEduscene() {
    let option = {
      textStyle: {
        align:'center',
        color: '#8493B9',
        fontFamily: 'Avenir Black Oblique'
      },
      grid: {
        top: '23%',
        left: '1%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      yAxis: {
        axisTick: {show: false },
        type: 'value',
        splitLine: { show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#5C86CF', 
            width: 2, 
          },
        },
        splitArea: {
          show: true,
          interval:0,
          areaStyle: {
              color: ['#0D2259', '#142966']
          }
        },
      },
      xAxis: {
        axisTick: {show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#559AE8', 
            width: 2, 
          },
        },
        type: 'category',
        data:  this.chartData.eduscene.names,
        axisLabel: {
          fontSize: '12',
          interval: 0, 
          formatter: function (params) {
            let newParamsName = '';
            const paramsNameNumber = params.length; // 文字总长度
            const provideNumber = 6; //一行显示几个字
            const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (let p = 0; p < rowNumber; p++) {
                const start = p * provideNumber;
                const end = start + provideNumber;
                const tempStr = p === rowNumber - 1 ? params.substring(start, paramsNameNumber) : params.substring(start, end) + '\n';
                newParamsName += tempStr;
                if (newParamsName.length > 22) {
                  newParamsName = newParamsName.substring(0, 19) + '...'
                  break
                }
              }
            } else {
              newParamsName = params;
            }
            return newParamsName;
          },
        },
      },
      series: [
        {
          data:this.chartData.eduscene.data,
          type: 'bar',
          showBackground: false,
          areaStyle: {
            color: ['#eceaea', '#f3f2f2'],
          },
          itemStyle : { 
            emphasis: {
              barBorderRadius:[0, 10, 10, 0]
            },
            normal: {
              barBorderRadius: [10, 10, 0, 0],
              label : {show: true, position: 'top', textStyle:{color:'#9CF2FF', fontWeight: 800, fontSize: '14px'}},
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: '#0063FA'},
                {offset: 1, color: '#2AD0FF'},
              ])
            }
          },
          barWidth : 25
        }
      ]
    };
    this.echart.eduscene.setOption(option);
  }


  initEchartExhibitor() {
    let option = {
      grid: {
        top: '23%',
        left: '3%',
        right: '5%',
        bottom: '1%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      xAxis: {
        type: 'value',
        splitLine: {
          show: false //去掉辅助线
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        }
  
      },
      yAxis: {
        type: 'category',
        data:this.chartData.exhibitor.names,
        splitLine: {
          show: false //去掉辅助线
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          inside: true,
          margin: 0,
          textStyle: {
            color: '#D0D8FF', //值的颜色
            fontSize: 14,
            verticalAlign: "bottom",
            align: "left",
            padding: [0, 0, 15, 0],
          },
        },
        inverse: true,
      },
      series: [{
        data:this.chartData.exhibitor.data,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: '#254871',
          borderRadius:5
        },
        barWidth: 8,
        itemStyle: {
          normal: {
            color: function (params){
              var colorList = ['#6FEADB','#6AC6F3','#7D65F3','#DB64BF', '#F07865'];
              return colorList[params.dataIndex % 5];
            },
            barBorderRadius: [5, 5, 5, 5],
          }
        },
        label: {
          normal: {
            align: 'right',
            show: true,
            position: [this.pos, -25],   //value的位置
            color: '#fff', //值的颜色
            fontSize: 14,
            fontFamily: 'Avenir Black Oblique',
            fontWeight: 800,
            formatter: (param) => {
              return this.formatNum(param.data);
            }
          }
        }
      }]
    };
    this.echart.exhibitor.setOption(option);
  }

  initEchartUv() {
    setTimeout(() => {
      this.uv_arr = this.num2arr(this.chartData.uv);
    })
    let option = {
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: this.getUvMax(),
          splitNumber: 5,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0,0,1,0,[
              {
                  offset: 0,
                  color: "#00C2FF"
              },
              {
                  offset: 0.5,
                  color: "#FEDB65"
              },
              {
                  offset: 1,
                  color: "#D200FF"
              }
            ]),
           
          },
          progress: {
            show: true,
            width: 20,
            roundCap: true,
          },
          pointer: {
            show: false
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 20,
              color: [
                [1, '#8A00A7']
              ],
            }
          },
          axisTick: {
            distance: -40,
            splitNumber: 9,
            lineStyle: {
              width: 3,
              color: '#6580E4'
            }
          },
          splitLine: {
            show: true,
            distance: -40,
            length: 10,
            lineStyle: {
              width: 4,
              color: '#6580E4',
            }
          },
          axisLabel: {
            distance: -30,
            color: '#fff',
            fontSize: 12,
            fontWeight: 'Regular',
            fontFamily: '思源黑体 CN Regular',
            formatter: function (value) {
              return Math.ceil(+value);
            },
          },
          anchor: {
            show: false
          },
          detail: {
            formatter: '浏览人次',
            color: '#66FFFF',
            fontFamlily: '思源黑体 CN Bold Bold',
            fontWeight: 'bold',
            offsetCenter: [0, 0],
          },
          data: [
            {
              value: this.chartData.uv
            }
          ]
        },
      ]
    };
    this.echart.uv.setOption(option);
  }

  initEchartProgress(key) {
    let option = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            roundCap: true,
            clip: false,
            itemStyle: {
              color: '#5CC5E7',
            },
            width: 6
          },
          axisLine: {
            show: false,
            lineStyle: {
              width: 20
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [this.chartData[key] / this.middle_max_pv * 100],
          detail: {
            show: false
          }
        }
      ]
    };
    this.echart[key].setOption(option);
  }

  getUvMax() {
    let max = [1000, 5000, 100000, 50000, 100000, 200000, 500000, 1000000, 2000000, 3500000, 5500000, 8000000];
    for(let num of max) if (this.chartData.uv < num * 0.7) return num
    return 9990000;
  }

  initEchartLive() {
    let option = {
      textStyle: {
        align:'right',
        color: '#8493B9',
        fontFamily: 'Avenir Black Oblique'
      },
      grid: {
        left: '1%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      xAxis: {
        axisTick: {show: false },
        type: 'value',
        splitLine: { show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#5C86CF', 
            width: 2, 
          },
        },
      },
      yAxis: {
        inverse: true,
        axisTick: {show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#559AE8', 
            width: 2, 
          },
        },
        type: 'category',
        data:this.chartData.live.names,
        axisLabel: {
          fontSize: '12',
          interval: 0, 
          formatter: function (params) {
            let newParamsName = '';
            const paramsNameNumber = params.length; // 文字总长度
            const provideNumber = 6; //一行显示几个字
            const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (let p = 0; p < rowNumber; p++) {
                const start = p * provideNumber;
                const end = start + provideNumber;
                const tempStr = p === rowNumber - 1 ? params.substring(start, paramsNameNumber) : params.substring(start, end) + '\n';
                newParamsName += tempStr;
                if (newParamsName.length > 22) {
                  newParamsName = newParamsName.substring(0, 19) + '...'
                  break
                }
              }
            } else {
              newParamsName = params;
            }
            return newParamsName;
          },
        },
        splitArea: {
          show: true,
          interval:0,
          areaStyle: {
              color: ['#0D2259', '#142966']
          }
        },
      },
      series: [
        {
          data:this.chartData.live.data,
          type: 'bar',
          showBackground: false,
          areaStyle: {
            color: ['#f3f2f2', '#eceaea'],
          },
          itemStyle : { 
            emphasis: {
              barBorderRadius:[0, 10, 10, 0]
            },
            normal: {
              barBorderRadius: [0, 10, 10, 0],
              label : {show: true, position: 'insideRight', textStyle:{color:'#000', fontWeight: 800, fontSize: '14px'}},
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {offset: 0, color: '#0063FA'},
                {offset: 1, color: '#2AD0FF'},
              ])
            }
          },
          barWidth : 20
        }
      ]
    };
    this.echart.live.setOption(option);
  }

  initEchartXywh() {
    let option = {
      textStyle: {
        align:'center',
        color: '#8493B9',
        fontFamily: 'Avenir Black Oblique'
      },
      grid: {
        top: '23%',
        left: '1%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      yAxis: {
        axisTick: {show: false },
        type: 'value',
        splitLine: { show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#5C86CF', 
            width: 2, 
          },
        },
        splitArea: {
          show: true,
          interval:0,
          areaStyle: {
              color: ['#0D2259', '#142966']
          }
        },
      },
      xAxis: {
        axisTick: {show: false },
        axisLine: { 
          show: true,
          lineStyle: {
            color: '#559AE8', 
            width: 2, 
          },
        },
        type: 'category',
        data:this.chartData.xywh.names,
        axisLabel: {
          fontSize: '12',
          interval: 0, 
          formatter: function (params) {
            let newParamsName = '';
            const paramsNameNumber = params.length; // 文字总长度
            const provideNumber = 6; //一行显示几个字
            const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
              for (let p = 0; p < rowNumber; p++) {
                const start = p * provideNumber;
                const end = start + provideNumber;
                const tempStr = p === rowNumber - 1 ? params.substring(start, paramsNameNumber) : params.substring(start, end) + '\n';
                newParamsName += tempStr;
                if (newParamsName.length > 22) {
                  newParamsName = newParamsName.substring(0, 19) + '...'
                  break
                }
              }
            } else {
              newParamsName = params;
            }
            return newParamsName;
          },
        },
      },
      series: [
        {
          data:this.chartData.xywh.data,
          type: 'bar',
          showBackground: false,
          areaStyle: {
            color: ['#eceaea', '#f3f2f2'],
          },
          itemStyle : { 
            emphasis: {
              barBorderRadius:[0, 10, 10, 0]
            },
            normal: {
              barBorderRadius: [10, 10, 0, 0],
              label : {show: true, position: 'top', textStyle:{color:'#9CF2FF', fontWeight: 800, fontSize: '14px'}},
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: '#0063FA'},
                {offset: 1, color: '#2AD0FF'},
              ])
            }
          },
          barWidth : 25
        }
      ]
    };
    this.echart.xywh.setOption(option);
  }

  initEchartJbdr() {
    let option = {
      grid: {
        top: '23%',
        left: '3%',
        right: '5%',
        bottom: '1%',
        containLabel: true,
        show:'true',
        borderWidth:'0'
     },
      xAxis: {
        type: 'value',
        splitLine: {
          show: false //去掉辅助线
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        }
  
      },
      yAxis: {
        type: 'category',
        data:this.chartData.jbdr.names,
        splitLine: {
          show: false //去掉辅助线
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          inside: true,
          margin: 0,
          textStyle: {
            color: '#D0D8FF', //值的颜色
            fontSize: 14,
            verticalAlign: "bottom",
            align: "left",
            padding: [0, 0, 15, 0],
          },
        },
        inverse: true,
      },
      series: [{
        data:this.chartData.jbdr.data,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: '#254871',
          borderRadius:5
        },
        barWidth: 8,
        itemStyle: {
          normal: {
            color: function (params){
              var colorList = ['#6FEADB','#6AC6F3','#7D65F3','#DB64BF', '#F07865'];
              return colorList[params.dataIndex % 5];
            },
            barBorderRadius: [5, 5, 5, 5],
          }
        },
        label: {
          normal: {
            align: 'right',
            show: true,
            position: [this.pos, -25],   //value的位置
            color: '#fff', //值的颜色
            fontSize: 14,
            fontFamily: 'Avenir Black Oblique',
            fontWeight: 800,
            formatter: (param) => {
              return this.formatNum(param.data);
            }
          }
        }
      }]
    };
    this.echart.jbdr.setOption(option);
  }


  formatNum(value) {
    if(!value&&value!==0) return 0;
  
    let str = value.toString();
    let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg,"$1,");
  }

  array_column(array, field) {
    return array.map(v => v[field]);
  }
}
