import {Component, Input, OnInit} from '@angular/core';
import {XROUTES} from 'src/app/shared/config';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-layout-main-2023',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class LayoutMain2023Component implements OnInit {

  @Input() navi = 0;

  S_ROOT_URL = environment.S_ROOT_URL;

  navs = [
    XROUTES[2023].index,
    XROUTES[2023].onlineexhibition,
    XROUTES[2023].onlinemap,
    XROUTES[2023].edulive,
    XROUTES[2023].onlinelook,
    XROUTES[2023].lookguide,
    XROUTES[2023].news,
    XROUTES[2023].report,
    XROUTES[2023].reservation,
    XROUTES[2023].previous
  ];

  footernavs = [
    [XROUTES[2023].onlineexhibition, XROUTES[2023].onlinelook, XROUTES[2023].report],
    [XROUTES[2023].onlinemap, XROUTES[2023].lookguide, XROUTES[2023].reservation],
    [XROUTES[2023].edulive, XROUTES[2023].news, XROUTES[2023].previous],
  ];

  email = 'shjiaobohui@163.com';

  constructor() {
  }

  ngOnInit() {

  }

}
