<app-layout-main-2022 [navi]="4">
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
    <div class="title">{{nav_select_title}}</div>
  </div>
  <div class="tabnav">
    <div class="nav">
      <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;云活动&ensp;&ensp;/&ensp;&ensp;{{nav_select_title}}
    </div>
  </div>
  <div class="container">
      <div class="left-nav">
          <div class="nav" *ngFor="let item of navs;" [ngClass]="item.id == nav_select_id ? 'active' : ''" (click)="goTab(item)">
            <img [src]="'assets/2022/imgs/activety/'+ item.img + (item.id == nav_select_id ? '_active' : '') + '.png'">{{item.title}}
          </div>
      </div>
      <div class="right-content">
        <app-activety-2022-tab1 *ngIf="nav_select_id == 1"></app-activety-2022-tab1>
        <app-activety-2022-tab2 *ngIf="nav_select_id == 2"></app-activety-2022-tab2>
        <app-activety-2022-tab3 *ngIf="nav_select_id == 3"></app-activety-2022-tab3>
      </div>
  </div>
  <img src="assets/2022/imgs/bg4@2x.jpg" alt="" class="bg1">
  <img src="assets/2022/imgs/bg3@2x.jpg" alt="" class="bg2">
</app-layout-main-2022>
