import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from '../app.guard';
import { XROUTES } from '../shared/config';
import { StudentactivityComponent } from './10_studentactivity';
import { HotsComponent } from './11_hots';
import { VrComponent } from './12_vr';
import { PreviousComponent } from './13_previous';
import { MainIndexComponent } from './1_mainindex';
import { LookguideComponent } from './2_lookguide';
import { OnlinelookComponent, OnlinelookDetailComponent } from './3_onlinelook';
import { ReservationComponent } from './4_reservation';
import { FeatureguideComponent } from './5_featureguide';
import { OnlinemapComponent } from './6_onlinemap';
import { EduliveComponent } from './7_edulive';
import { RedresourcesComponent } from './8_redresources';
import { NewsComponent, NewsDetailComponent } from './9_news';

const routes: Routes = [
  {
    path: "",
    children: [
      { path: "lookguide", canActivate: [AppGuard], component: LookguideComponent, pathMatch: "full", data: { title: XROUTES[2021].lookguide.ptitle }},
      { path: "onlinelook", canActivate: [AppGuard], component: OnlinelookComponent, pathMatch: "full", data: { title: XROUTES[2021].onlinelook.ptitle }},
      { path: "onlinelook/:id", canActivate: [AppGuard], component: OnlinelookDetailComponent, pathMatch: "full", data: { title: '详情 - ' + XROUTES[2021].onlinelook.ptitle }},
      { path: "reservation", canActivate: [AppGuard], component: ReservationComponent, pathMatch: "full", data: { title: XROUTES[2021].reservation.ptitle }},
      { path: "featureguide", canActivate: [AppGuard], component: FeatureguideComponent, pathMatch: "full", data: { title: XROUTES[2021].featureguide.ptitle }},
      { path: "onlinemap", canActivate: [AppGuard], component: OnlinemapComponent, pathMatch: "full", data: { title: XROUTES[2021].onlinemap.ptitle }},
      { path: "edulive", canActivate: [AppGuard], component: EduliveComponent, pathMatch: "full", data: { title: XROUTES[2021].edulive.ptitle }},
      { path: "redresources", canActivate: [AppGuard], component: RedresourcesComponent, pathMatch: "full", data: { title: XROUTES[2021].redresources.ptitle }},
      { path: "news", canActivate: [AppGuard], component: NewsComponent, pathMatch: "full", data: { title: XROUTES[2021].news.ptitle }},
      { path: "news/:id", canActivate: [AppGuard], component: NewsDetailComponent, pathMatch: "full", data: { title: '详情 - ' + XROUTES[2021].news.ptitle }},
      { path: "studentactivity", canActivate: [AppGuard], component: StudentactivityComponent, pathMatch: "full", data: { title: XROUTES[2021].studentactivity.ptitle }},
      { path: "hots", canActivate: [AppGuard], component: HotsComponent, pathMatch: "full", data: { title: XROUTES[2021].hots.ptitle }},
      { path: "vr", canActivate: [AppGuard], component: VrComponent, pathMatch: "full", data: { title: XROUTES[2021].vr.ptitle }},
      { path: "previous", canActivate: [AppGuard], component: PreviousComponent, pathMatch: "full", data: { title: XROUTES[2021].previous.ptitle } },
      { path: "", canActivate: [AppGuard], component: MainIndexComponent, pathMatch: "full", data: { title: XROUTES[2021].index.ptitle }},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class M2021RoutingModule { }
