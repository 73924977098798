import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { M2022RoutingModule } from './m2022-routing.module';
import { SharedModule } from '../shared/shared.module';
import { IndexComponent } from './1_mainindex';
import { OnlinemapComponent } from './2_onlinemap';
import { EduliveComponent } from './3_edulive';
import { OnlinelookComponent } from './4_onlinelook';
import { OnlinelookTab1Component } from './4_onlinelook/tab1';
import { OnlinelookTab4Component } from './4_onlinelook/tab4';
import { OnlinelookTab2Component } from './4_onlinelook/tab2';
import { OnlinelookTab5Component } from './4_onlinelook/tab5';
import { OnlinelookTab3Component } from './4_onlinelook/tab3';
import { ActivetyComponent } from './5_activety';
import { ActivetyTab1Component } from './5_activety/tab1';
import { ActivetyTab2Component } from './5_activety/tab2';
import { ActivetyTab3Component } from './5_activety/tab3';
import { LookguideComponent } from './6_lookguide';
import { Hots2022Component } from './7_hots';
import { News2022Component } from './8_news';
import { Previous2022Component } from './9_previous';
import { LookguideTab1Component } from './6_lookguide/tab1';
import { LookguideTab2Component } from './6_lookguide/tab2';
import { ActivetyTab3DetailComponent } from './5_activety/tab3/detail';
import { NewsDetailComponent } from './8_news/detail';
import { ModalComponent } from './modal';
import { OnlinelookTab6Component } from './4_onlinelook/tab6';


@NgModule({
  declarations: [
    IndexComponent, 
    OnlinemapComponent, 
    EduliveComponent, 
    OnlinelookComponent,
    OnlinelookTab1Component, 
    OnlinelookTab2Component, 
    OnlinelookTab4Component,
    OnlinelookTab5Component,
    OnlinelookTab3Component,
    OnlinelookTab6Component,
    ActivetyComponent,
    ActivetyTab1Component,
    ActivetyTab2Component,
    ActivetyTab3Component,
    ActivetyTab3DetailComponent,
    News2022Component,
    NewsDetailComponent,
    Hots2022Component,
    Previous2022Component,
    LookguideComponent,
    LookguideTab1Component,
    LookguideTab2Component,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    M2022RoutingModule
  ]
})
export class M2022Module { }
