import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// // 引入代理配置
// import { rewriteTarget } from '@angular-devkit/core/src/utils/rewrite-target'
// const { provideModuleMapSupport } = require('@ngtools/webpack');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


// rewriteTarget('../proxy.conf.json');
