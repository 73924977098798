import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class OnlinelookDetailComponent implements OnInit {

  row: any;
  loaded = false
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id')
    this.getdata(id);
  }

  getdata(id){
    this.http.get('/pc/vlog/' + id).subscribe((res: any) => {
      // res.vlog ='https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/vod.linktour.com/b6d88f90341c4dab8ed8001748974fac/0299dfe79f8f40e892c59692cdc27c63-f627270ac97656606465bd58f6ecf3be-sd.mp4'
      this.row = res
      this.loaded = true
    })
  }

  goback() {
    this.router.navigateByUrl('/2021/onlinelook')
  }
}
