import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

interface Config {
  videos: {
    name: string;
    summary: string;
    file: string;
    poster: string;
  }[];
  home: string[];
  gallerys: any;
  news: { id:number,title: string; content: string }[];
  aboutus: any;
  team: any;
}

@Injectable({
  providedIn: "root"
})
export class CacheService {
  constructor(private router: Router) {}

  get(key: string): string {
    return localStorage.getItem(key);
  }

  set(key: string, data: any): void {
    localStorage.setItem(key, data);
  }

  setJson(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getJson(key: string):any {
    return JSON.parse(localStorage.getItem(key));
  }

  remove(key: string): void{
    localStorage.removeItem(key);
  }

  setconfig(data: any): void {
    localStorage.setItem("config", JSON.stringify(data));
  }

  getconfig(): Config {
    if (localStorage.getItem("config") == null) {
      //this.router.navigateByUrl("/");
      location.href = "/";
      return;
    }
    return JSON.parse(localStorage.getItem("config"));
  }
}
