<app-layout-main [navi]='2' *ngIf="loaded">
  <div class="onlinelook-wrapper gl-min-width">
    <div class="onlinelook-content gl-container-width">
      <div class="ptitle"><img src="assets/imgs/3/title.png"/></div>
      <div class="onlinelook-bg">
        <img src="assets/imgs/3/online-bg.png"/>
      </div>
      <div class="search-wrapper">
        <input type='text'/>
        <img src="assets/imgs/3/search-bg.png"/>
      </div>
      <div class="online-container">
        <!-- <div class="navs-wrapper" style="background-image: url(assets/imgs/3/nav-bg.png);">
          <div class="nav-item active">区教育区</div>
          <div class="nav-item">长三角区域教育展区</div>
          <div class="nav-item">职业教育展区</div>
          <div class="nav-item">上海市大中小学校红色育人实践展区</div>
          <div class="nav-item">高等教育展区</div>
          <div class="nav-item">特色学生活动</div>
        </div> -->
        <div class="navs-wrapper" style="background-image: url(assets/imgs/3/nav-bg.png);">
          <div class="nav-item" (click)="gotab(index)" [ngClass]="{'active': sindex == index}" *ngFor="let key of Objectkeys(res); let index = index">{{res[key].name}}</div>
        </div>
        <div class="nav-content">
          <div class="nrows">
            <div class="nrow" *ngFor="let item of res[sindex].vlog" (click)="toview(item)">
              <div class="df" *ngIf="item.file"><img [src]="U_ROOT_URL + item.file + osslink"/></div>
              <div class="df" *ngIf="!item.file"><img src="assets/imgs/3/df.png"/></div>
              <div class="infors" style="background-image: url(assets/imgs/index/online-intro-bg.png);">
                <div class="ileft">
                  <div class="ititle">{{item.name}}</div>
                  <div class="pos">展位：{{item.desktop}}</div>
                </div>
                <div class="iright" >
                  <div class="hot-wrapper">
                    <img src="assets/imgs/common/fire.png"/>
                  </div>
                  <div class="txt">人气值：{{item.praise}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="nav-pfooter">
            <img class="pre" src="assets/imgs/2/arrow-dis.png"/>
            <div class="txt">上一页</div>
            <div class="txt"> 1  </div>
            <div class="txt active"> 2  </div>
            <div class="txt"> 3  </div>
            <div class="txt"> 4  </div>
            <div class="txt active">下一页</div>
            <img class="next" src="assets/imgs/2/arrow-en.png"/>
          </div> -->
          <div class="nav-pfooter"></div>
        </div>
      </div>
    </div>
  </div>
</app-layout-main>
