import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onlinemap',
  templateUrl: './onlinemap.component.html',
  styleUrls: ['./onlinemap.component.styl']
})
export class OnlinemapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
