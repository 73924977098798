<div class="header-wrapper gl-min-width" style="background-image: url('assets/imgs/common/header-bg.png')">
  <div class="header-content gl-container-width">
    <div class="logo-group">
      <img src="assets/imgs/common/header-logo.png"/>
    </div>
    <div class="navs-group">
      <div class="nav-item " *ngFor="let item of navs;let i=index" [ngClass]="{'active':i===navi}">
        <a [href]="item.url">
          {{item.title}}
        </a>
      </div>
    </div>
  </div>
</div>
<div class="body-wrapper">
  <ng-content></ng-content>
</div>
<div class="footer-wrapper gl-min-width">
  <div class="footer-container">
    <div class="footer-content gl-container-width">
      <div class="fc-logo">
        <img src="/assets/imgs/common/footer-logo.png"/>
      </div>
      <div class="fc-intro-group">
        <div class="mini-qrcode fig-item">
          <div class="img">
            <img src="/assets/imgs/common/footer-min-qrcode.png"/>
          </div>
          <div class="txt">
            <p>扫码使用小程序</p>
            <p>观看教博会</p>
          </div>
        </div>
        <div class="navs fig-item">
          <div class="figi-title">网站导航</div>
          <div class="navs-link">
            <div class="nav-li" *ngFor="let items of footernavs">
              <a [href]="item.url" *ngFor="let item of items">{{item.title}}</a>
            </div>
          </div>
        </div>
        <div class="contact fig-item">
          <div class="figi-title">联系我们</div>
          <p>电话：400-0000-8888</p>
          <p>展会微信</p>
          <img src="/assets/imgs/common/footer-qrcode2.png"/>
        </div>
        <!-- <div class="link fig-item">
          <div class="figi-title">友情链接</div>
          <p><a target="_blank" href="http://www.hongyear.com/"><img src="/assets/imgs/common/sp1.png"/></a></p>
          <p><a target="_blank" href="https://www.wenxiangcn.com/"><img src="/assets/imgs/common/sp2.png"/></a></p>
        </div> -->
      </div>
    </div>
  </div>
  <div class="footer-bg">
    <img src="/assets/imgs/common/footer-bg.png"/>
  </div>
</div>

