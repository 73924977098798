import { Component, Input, OnInit } from '@angular/core';
import { XROUTES } from 'src/app/shared/config';

@Component({
  selector: 'app-layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class LayoutMainComponent implements OnInit {

  @Input() navi: number = 0

  navs = [
    XROUTES[2021].index,
    XROUTES[2021].lookguide,
    XROUTES[2021].onlinelook,
    XROUTES[2021].reservation,
    XROUTES[2021].featureguide,
    XROUTES[2021].onlinemap,
    XROUTES[2021].edulive,
    XROUTES[2021].redresources,
    XROUTES[2021].news,
    XROUTES[2021].studentactivity,
    XROUTES[2021].hots,
    XROUTES[2021].vr,
    XROUTES[2021].previous
  ]

  footernavs = [
    [ XROUTES[2021].index ],
    [ XROUTES[2021].onlinelook, XROUTES[2021].onlinemap, XROUTES[2021].edulive, XROUTES[2021].redresources ],
    [ XROUTES[2021].lookguide, XROUTES[2021].news, XROUTES[2021].featureguide, XROUTES[2021].reservation ],
    [ XROUTES[2021].vr, XROUTES[2021].studentactivity, XROUTES[2021].hots, XROUTES[2021].previous ]
  ]

  constructor() { }

  ngOnInit() {

  }

}
