import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edulive-2022',
  templateUrl: './edulive.component.html',
  styleUrls: ['./edulive.component.styl']
})
export class EduliveComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  show_modal = false
  row :any = {}
  U_ROOT_URL = environment.U_ROOT_URL

  edunews:any = []

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.http.get('/pc/live2022').subscribe((res: any) => {
      this.edunews = res.edunews
     })
  }

  showModal(id) {
    this.show_modal = true
    this.row = this.edunews[id]
  }


  noC(e) {
    e.stopPropagation();
    return false;
  }

}
