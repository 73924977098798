export const environment = {
  production: false,
  host: '//api.expo.shnu.edu.cn/frontend', // pro
  // host: '//expo-api.ghz-tech.com:8185/frontend', // dev
  U_ROOT_URL: '//u.expo.shnu.edu.cn',
  S_ROOT_URL: '//expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com',
  US_ROOT_URL: '//u.expo.shnu.edu.cn',
  // QR_ROOT_URL: '//srv.linktour.com/qrcode?s=200&p=',
  QR_ROOT_URL: '//api.expo.shnu.edu.cn/miniprogram-eduexpo/v1/qrcode?size=200&url=',
  TRACKER_INDEX: 'test',
};

// https://api.expo.shnu.edu.cn/miniprogram-eduexpo/v1/qrcode?url=https://www.baidu.com&size=200
// https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com/app/edu-expo/2022/image/map/navigate/b.jpg

// //expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com',
// https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/s.linktour.com/app/edu-expo/2022/image/map/navigate/b.jpg
