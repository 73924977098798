<app-layout-main-2022 [navi]="1">
  <div class="banner-wrapper gl-min-width">
    <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px"/>
    <div class="title">电子地图</div>
  </div>

  <div class="tabnav">
      <div class="nav">
        <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;电子地图
      </div>
  </div>
  
  <div class="onlinelook-wrapper gl-min-width">
    <div class="onlinelook-content gl-container-width">
      <iframe src="https://edumap.shnu.edu.cn/start"></iframe>
    </div>
  </div>
 
</app-layout-main-2022>
