<div class="container">
    <div class="dates">
        <span class="date">{{dates.date}}</span>
        <span class="time">{{dates.time}}</span>
        <span class="week">{{dates.week}}</span>
    </div>
    <div class="row-chart">
        <div class="column-chart-list">
            <div class="box">
                <div class="chart" id="map"></div>
                <div class="title">上海教育影响力电子地图-TOP5</div>
            </div>
            <div class="box">
                <div class="chart" id="eduscene"></div>
                <div class="title">教育应用场景案例-TOP5</div>
            </div>
            <div class="box">
                <div  class="chart" id="exhibitor"></div>
                <div class="title">参展单位-TOP5</div>
            </div>
        </div>
        <div class="column-chart-list middle">
            <div class="middle-box-1">
                <div id="uv" class="chart"></div>
                <div class="numlist">
                    <div *ngFor="let v of nums" class="nump">
                        <img src="/assets/2022/imgs/echarts/num-bg.png" alt="">
                        <div class="num">{{uv_arr[v] ? uv_arr[v] : 0}}</div>
                    </div>   
                </div>
            </div>
            <div class="middle-box">
                <div class="chart">
                    <div class="content">
                        <div class="item">
                            <img src="/assets/2022/imgs/echarts/inauguration.png" alt="">
                            <div id="inauguration_pv" class="progress"></div>
                        </div>
                        <div class="right">
                            <span class="num">{{chartData.inauguration_pv}}</span>
                            <br>
                            <span class="label">开/闭幕式(人)</span>
                        </div>
                    </div>
                    <div  class="content">
                        <div class="item">
                            <img src="/assets/2022/imgs/echarts/gflt.png" alt="">
                            <div id="gflt_pv" class="progress"></div>
                        </div>
                        <div class="right">
                            <div class="num">{{chartData.gflt_pv}}</div>
                            <div class="label">高峰论坛(人)</div>
                        </div>
                    </div>
                    <div  class="content">
                        <div class="item">
                            <img src="/assets/2022/imgs/echarts/live.png" alt="">
                            <div id="live_pv" class="progress"></div>
                        </div>
                        <div class="right">
                            <div class="num">{{chartData.live_pv}}</div>
                            <div class="label">教育大直播(人)</div>
                        </div>
                    </div>
                    <div  class="content">
                        <div class="item">
                            <img src="/assets/2022/imgs/echarts/edunews.png" alt="">
                            <div id="edunews_pv" class="progress"></div>
                        </div>
                        <div class="right">
                            <div class="num">{{chartData.edunews_pv}}</div>
                            <div class="label">教育新闻坊(人)</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle-box">
                <div class="chart2">
                    <div class="item">
                        <img src="/assets/2022/imgs/echarts/map-bg.png" alt="">
                        <div class="title">主题教育电子地图</div>
                        <span class="num">{{mapData[1]}}</span>
                    </div>
                    <div class="item">
                        <img src="/assets/2022/imgs/echarts/map-bg.png" alt="">
                        <div class="title">电子地图覆盖学校及机构</div>
                        <span class="num">{{mapData[2]}}</span>
                    </div>
                    <div class="item">
                        <img src="/assets/2022/imgs/echarts/map-bg.png" alt="">
                        <div class="title">电子地图点位</div>
                        <span class="num">{{mapData[3]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="column-chart-list">
            <div class="box">
                <div class="chart" id="live"></div>
                <div class="title">上海教育大直播-TOP5 <span class="live_label">（截止至{{dates.date_suf}}，各观看平台数据汇总）</span></div>
            </div>
            <div class="box">
                <div class="chart" id="xywh"></div>
                <div class="title">校园文化活动-TOP5</div>
            </div>
            <div class="box">
                <div  class="chart" id="jbdr"></div>
                <div class="title">教博达人-TOP5</div>
            </div>
        </div>
    </div>
</div>