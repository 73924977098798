<app-layout-main [navi]='9' *ngIf="loaded">
  <div class="sat-wrapper gl-min-width">
    <div class="sat-content gl-container-width">
        <div class="ptitle">
          <img src="assets/imgs/10/title.png" />
        </div>
        <div class="sat-bg">
          <img src="assets/imgs/10/sat-bg.png"/>
        </div>
        <div class="sat-container">
          <div class="sat-list">
            <div class="news-list-row" *ngFor="let item of rows;let i=index" (click)="toview(i)">
              <div class="nlr-dfimg">
                <img [src]="item.file != '' ? sroot + item.file + '?x-oss-process=image/resize,w_300':'assets/imgs/10/sat-df.png'"/>
                <div class="tags-group">
                  <div class="tags-rows">
                    <div class="tag" *ngFor="let tag of item.target">{{tag}}</div>
                  </div>
                </div>
              </div>
              <div class="infor-group">
                <div class="txt ttitle">{{item.name}}</div>
                <div class="txt time">{{item.showtime}}</div>
                <div class="txt intro" [innerHTML]="item.description"> </div>
                <div class="btns">
                  <div class="btn">
                    <div class="txt">查看详情</div>
                    <img src="assets/imgs/index/toright.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="news-recommand-list" style="background-image: url(assets/imgs/10/news-recommand-bg.png);">
            <div class="nrl-title" style="background-image: url(assets/imgs/10/news-recommand-title-bg.png);">相关新闻</div>
            <div class="nrl-rows">
              <div class="nrl-row" *ngFor="let item of [1,1,1,1,1,1,1,1,1,1,1]" (click)="toview()" >
                <img src="assets/imgs/9/news-df1.png" />
                <div class="ntitle">直播标题直播标题直播标题</div>
                <div class="ntime">开播时间：2021年06月07日 12:00 ～ 13:00</div>
                <div class="nintro">教育局领导谈未来教育方向，教育局领导谈未来教育方向，教育局领导谈未来教育方向，教育局领导谈未来教育方向</div>
              </div>
            </div>
          </div> -->
        </div>
    </div>
  </div>
</app-layout-main>

<div class="popup-wrapper" *ngIf="ishow">
  <div class="popup-container">
    <div class="popup-body">
      <div class="ptile">{{row.name}}</div>
      <div class="tags">
        <div class="tag" *ngFor="let tag of row.target">{{tag}}</div>
        <div class="tag">{{row.showtime}}</div>
      </div>
      <div class="orgs">
        <div class="org txt">{{row.organizer.join('、')}}</div>
      </div>
<!-- <<<<<<< HEAD -->
      <!-- <div class="description">{{row.description}}</div> -->
      <div class="editor" [innerHTML]="row.description"></div>

    </div>
    <div class="close-img" (click)="ishow=false">
      <img src="assets/imgs/10/close.png"/>
    </div>
  </div>
</div>
