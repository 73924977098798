<div class="contains">
   <!-- <div class="items">
      <img src="/assets/2022/imgs/lookguide/navi_pic1@2x.png" alt="" width="415px" height="186px">
      <div class="info">
         <span style="margin-top: 5px">现场宣传手册</span>
         <div><img src="/assets/2022/imgs/lookguide/navi_btn@2x.png" alt=""></div>
      </div>
   </div> -->
   <div class="items">
      <img src="/assets/2022/imgs/lookguide/navi_pic2@2x.png" alt="" width="415px" height="186px">
      <div class="info">
         <span style="margin-top: 5px">线上宣传手册</span>
         <div><a [href]="tour.file" target="_blank"><img src="/assets/2022/imgs/lookguide/navi_btn@2x.png" alt=""></a></div>
      </div>
   </div>
</div>