import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-lookguide-2022-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.styl']
})
export class LookguideTab1Component implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  tour:any = {}

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.http.get('/pc/tour').subscribe((res) => {
      this.tour = res
    })
  }

}
