import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-redresources',
  templateUrl: './redresources.component.html',
  styleUrls: ['./redresources.component.styl']
})
export class RedresourcesComponent implements OnInit {

  showPoup = false
  rows: any
  // rows: any = [
  //   [
  //   {
  //     df: 'assets/imgs/8/p1.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p1.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p4.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  // ],[
  //   {
  //     df: 'assets/imgs/8/p2.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p3.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p3.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  // ],[
  //   {
  //     df: 'assets/imgs/8/p4.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p1.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p2.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },],[
  //   {
  //     df: 'assets/imgs/8/p3.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },
  //   {
  //     df: 'assets/imgs/8/p1.png',
  //     name: '上海外国语小学',
  //     intro: '上海市世界外国语小学创办于1993年，2005年8月起转为民办学校，学校分本部、 境外部、PYP部（ IB0国际文凭组织小学项目部 ），学校现在有两个校区，桂林西街101弄56号属该校....',
  //     tags: ['大学','985','展馆']
  //   },]
  // ]

  res: any;
  loaded = false
  Objectkeys = Object.keys
  sindex = 0
  U_ROOT_URL = environment.U_ROOT_URL
  osslink = '?x-oss-process=image/resize,w_300';
  ossbiglink = '?x-oss-process=image/resize,w_900';
  showingrow: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.getdata()
  }

  getdata(){
    this.http.get('/pc/redschool').subscribe((res) => {
      this.res = res
      this.loaded = true
      this.rows = this.res[this.sindex].rows
    })
  }

  gotab(index){
    this.sindex = index
    this.rows = this.res[this.sindex].rows
  }

  showdetail(index1, index2){
    this.showingrow = this.res[this.sindex].rows[index1][index2];
    this.showPoup = true;
  }
}
