<app-layout-main [navi]='2' *ngIf="loaded">
  <div class="onlinelook-detail-wrapper gl-min-width">
    <div class="onlinelook-detail-content gl-container-width">

        <ng-container *ngIf="false">
          <div class="xptitle" style="background-image: url(assets/imgs/3/ptitle-bg.png);">
              长宁教育局
          </div>
          <div class="onlinelook-detail-no-body">
            <img src="assets/imgs/3/no-bg.png"/>
          </div>
        </ng-container>

        <ng-container *ngIf="true">
          <div class="gap-empty"></div>
          <div class="body">
              <div class="df" *ngIf="!row.vlog"><img src="assets/imgs/3/detail2-df.png"/></div>
              <div class="edu-video-group" *ngIf="row.vlog">
                <video
                    id="fullscreen-video"
                    name="media"
                    controlslist="nodownload"
                    preload="auto"
                    controls
                    webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" >
                    <source type="video/mp4" [src]="row.vlog" >
                    <p>你的浏览器不支持 <code>Video</code> </p>
                </video>
                <div class="play-group">
                  <img src="assets/imgs/common/i-play.png"/>
                </div>
              </div>
              <div class="txts" style="background-image: url(assets/imgs/3/detail-title-bg.png);">
                <div class="txt">{{row.name}}</div>
                <div class="txt">展位：{{row.desktop}}</div>
                <div class="txt">
                  <img src="assets/imgs/common/fire.png"/>
                  <div class="stxt">人气值：{{row.praise}}</div>
                </div>
              </div>
          </div>
           <div class="body-bg">
            <img src="assets/imgs/3/detail2-bg.png"/>
          </div>
        </ng-container>

        <div class="goback" (click)="goback()">
          <img src="assets/imgs/3/goback.png"/>
        </div>
    </div>
  </div>
</app-layout-main>
