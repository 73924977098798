<app-layout-main [navi]='3'>

  <div class="reservation-wrapper gl-min-width">
    <div class="reservation-content gl-container-width">
       <div class="title">
         <img src="/assets/imgs/4/title.png"/>
       </div>
       <div class="qrcode">
        <img src="/assets/imgs/4/qrcode.png"/>
      </div>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width">
    <img src="/assets/imgs/common/gap-bg.png"/>
  </div>
    
</app-layout-main>