<app-layout-main-2022 *ngIf="loaded" [navi]="-1">
  <div class="banner-wrapper gl-min-width imgs-wrapper">
    <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/banner@2x.jpg?x-oss-process=image/resize,w_1200'" height="360px" width="100%"/>
  </div>
  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/exhibition_tilte@2x.png" width="301px" height="98px"/>
  </div>
  <div class="gl-min-width imgs-wrapper">
    <div class="yzl">
      <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/exhibition_banner@2x.jpg?x-oss-process=image/resize,w_1200'" width="1200px" height="360px"/>
      <img src="/assets/2022/imgs/code2@2x.jpg" alt="" width="136px" height="136px" class="yzl-qrcode">
    </div>
  </div>
  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/news_title@2x.png" width="301px" height="98px"/>
  </div>
  <div class="gl-min-width imgs-wrapper bg1">
    <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/bg1@2x.jpg?x-oss-process=image/resize,w_1920'" width="100%"/>
  </div>

  <div class="news-wrapper gl-min-width">
    <div class="news-content gl-container-width">
      <div class="news-body">
        <div class="news-rows">
          <div class="news-row" *ngFor="let item of res.news">
            <div class="dfcover">
              <img  *ngIf="item.file" src="assets/imgs/index/news-defualt-img.png"/>
            </div>
            <div class="news-infor">
              <div class="news-title">{{item.name}}</div>
              <div class="news-date">{{item.created}}</div>
              <!-- <div class="news-intro">在10月21日举行的区域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈...</div> -->
              <div class="news-view" (click)="gopage('/2022/news/' + item.id)">
                <span style="cursor: pointer">查看全文</span>
                <img src="assets/imgs/index/toright.png"/>
              </div>
            </div>
          </div> 
        </div>
        <div class="news-btns">
          <div class="news-btn">
            <a href="/2022/news" class="btn">查看更多</a>
          </div>
        </div>
      </div>
      <div class="bg2">
        <img src="/assets/2022/imgs/bg2@2x.jpg" width="204px" height="178px"/>
      </div>
    </div>
  </div>

  <div class="hots-wrapper gl-min-width">
    <div class="hots-content gl-container-width">
      <div class="hots-body">

      </div>
      <div class="hots-bg">
        <img src="assets/2022/imgs/hot@2x-pichi.png"/>
        <div class="list-btn">
          <div class="lbitem" *ngFor="let item of res.etop3">
            <div class="title">{{item.name}} </div>
            <div class="popular">人气值：{{item.pv}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <img src="/assets/2022/imgs/bg3@2x.jpg" width="540px" height="480px"/>
    </div>
  </div>

  <div class="yty-wrapper gl-min-width">
    <div class="yty-content">
      <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/experience_banner@2x.jpg?x-oss-process=image/resize,w_597'" alt="" width="597px" height="433px" style="margin-left: 30px">
      <div class="yty-right">
          <img src="/assets/2022/imgs/experience_title@2x.png" alt="" width="192px" height="141px">
            <br>  
            <div *ngFor="let item of onlinelook;let first=first;let i=index" class="fl" [style]="first ? 'width:100%;margin:45px 0 32px -15px' : ''">
              <a *ngIf="first; else ytyelse" class="first" [href]="'/2022/onlinelook?tab='+ item.id">
                <img src="/assets/2022/imgs/tip01@2x.png" alt="" width="48px" height="48px"> &nbsp; {{item.name}}
              </a>
              <ng-template #ytyelse>
                <a [href]="'/2022/onlinelook?tab='+ item.id"><span class="yty-pm">{{'0' + (i+1)}}</span>{{item.name}}</a>
              </ng-template>
            </div>
            <div>
              <a href="/2022/onlinelook" class="btn">查看更多</a>
            </div>
      </div>
    </div>
    <div class="bg4">
      <img src="/assets/2022/imgs/bg4@2x.jpg" width="666px" height="666px"/>
    </div>
  </div>

  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/navi_title@2x.png" width="301px" height="98px"/>
  </div>

  <div class="gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/navi_left.png" width="600px" height="353px"/>
    <div style="text-align: center;margin-left: 69px;">
      <img src="/assets/2022/imgs/navi_right@2x.png" width="318px" height="270px"/>
      <div style="margin-top: 57px">
        <a href="/2022/lookguide?tab=1" class="btn" style="margin-right: 10px">宣传手册</a>
        <a *ngIf="res.wzjb" class="btn" [href]="res.wzjb" target="_blank">玩转教博</a>
      </div>
    </div>
    <div class="bg5">
      <img src="/assets/2022/imgs/bg5@2x.jpg" width="675px" height="675px"/>
    </div>
  </div>

  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/activity_title@2x.png" width="301px" height="98px"/>
  </div>

  <div class="activety-type-list">
    <div 
        class="activety-type" 
        *ngFor="let activety_type of activety_types" 
        [ngClass]="activety_type.id == select_type_id ? 'active' : ''"
        (click)="changeType(activety_type.id)" >
        {{activety_type.name}}
     </div>
  </div>

  <div class="gl-min-width" [ngClass]="select_type_id != 1 ? 'hide' : ''" style="text-align: center;" >
    <div [ngClass]="inauguration.status != EDU_EXPO_LIVE_STATUS_WAITING ? 'hide' : ''">
      <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/activity_kv@2x.jpg?x-oss-process=image/resize,w_1200'" width="1200px" height="629px"/>
      <div style="margin-top: 35px;margin-bottom: 180px;">
        <a [href]="'/2022/activety?tab='+select_type_id" class="btn">查看更多</a>
      </div>
    </div>
    <div [ngClass]="inauguration.status != EDU_EXPO_LIVE_STATUS_LIVING || !inauguration.hls ? 'hide' : ''">
      <video
      #livevideo
      controls
      class="video-js vjs-default-skin vjs-big-play-centered video"
      width="100%" 
      height="100%"
      data-setup='{"liveui": true}'
      disablePictureInPicture
      >
        <source [src]="inauguration.hls" type="application/x-mpegURL">
        <p>你的浏览器不支持 <code>Video</code> </p>
      </video>
      <div style="margin-top: 35px;margin-bottom: 180px;">
        <a [href]="'/2022/activety?tab='+select_type_id" class="btn">查看更多</a>
      </div>
    </div>
    <div [ngClass]="inauguration.status != EDU_EXPO_LIVE_STATUS_END && inauguration.status != EDU_EXPO_LIVE_STATUS_PADDING ? 'hide' : ''">
      <video
      id="endVideo"
      class="video"
      controls
      width="100%" 
      height="100%"
      disablePictureInPicture
      >
        <source [src]="inauguration.hls" type="video/mp4">
        <p>你的浏览器不支持 <code>Video</code> </p>
      </video>
      <div style="margin-top: 35px;margin-bottom: 180px;">
        <a [href]="'/2022/activety?tab='+select_type_id" class="btn">查看更多</a>
      </div>
    </div>
  </div>

  <div class="news-wrapper gl-min-width" *ngIf="select_type_id == 2">
    <div class="news-content gl-container-width">
      <div class="news-body">
        <div class="news-rows">
          <div class="news-row" *ngFor="let item of gflt_data">
            <div class="dfcover">
              <img  [src]="S_ROOT_URL + item.cover + '?x-oss-process=image/resize,w_600'"/>
            </div>
            <div class="news-infor">
              <div class="news-title" *ngFor="let subject of item.subject_rich">{{subject}}</div>
              <div class="news-date">直播时间：&nbsp;&nbsp; {{item.live_time}}</div>
              <!-- <div class="news-intro">在10月21日举行的区域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈..域教育大专家直播——嘉善教育访谈中访谈中访谈...</div> -->
              <div class="news-view" (click)="gopage('/2022/activety?tab='+select_type_id)">
                <span style="cursor: pointer">了解更多</span>
                <img src="assets/imgs/index/toright.png"/>
              </div>
            </div>
          </div> 
        </div>
        <div class="news-btns">
          <div class="news-btn">
            <a [href]="'/2022/activety?tab='+select_type_id" class="btn">查看更多</a>
          </div>
        </div>
      </div>
      <div class="bg2">
        <img src="/assets/2022/imgs/bg2@2x.jpg" width="204px" height="178px"/>
      </div>
    </div>
  </div>

  <div class="news-wrapper gl-min-width" *ngIf="select_type_id == 3">
    <div class="news-content gl-container-width">
      <div class="news-body">
        <div class="news-rows">
          <div class="news-row" *ngFor="let item of activitys">
            <div class="dfcover">
              <img  [src]="item.file + '?x-oss-process=image/resize,w_600'"/>
            </div>
            <div class="news-infor">
              <div class="news-title">{{item.name}}</div>
              <div class="news-date">{{item.created}}</div>
              <div class="news-intro" [innerHtml]="item.description"></div>
              <div class="news-view" (click)="gopage('/2022/activety?tab='+select_type_id)">
                <span style="cursor: pointer">了解更多</span>
                <img src="assets/imgs/index/toright.png"/>
              </div>
            </div>
          </div> 
        </div>
        <div class="news-btns">
          <div class="news-btn">
            <a [href]="'/2022/activety?tab='+select_type_id" class="btn">查看更多</a>
          </div>
        </div>
      </div>
      <div class="bg2">
        <img src="/assets/2022/imgs/bg2@2x.jpg" width="204px" height="178px"/>
      </div>
    </div>
  </div>


  <div class="bg6">
    <img src="/assets/2022/imgs/bg6@2x.jpg" height="307px" width="350px"/>
  </div>


  <div class="ticket-wrapper gl-min-width imgs-wrapper" style="margin-top: 0px">
    <img src="/assets/2022/imgs/map_title@2x.png" width="301px" height="98px"/>
  </div>

  <div class="gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/map@2x.jpg" width="1200px" height="735px" (click)="gopage('/2022/onlinemap')"/>
  </div>

  <div class="imgs-wrapper bg7">
    <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/bg7@2x.jpg?x-oss-process=image/resize,w_1920'"/>
  </div>


  <div class="ticket-wrapper gl-min-width imgs-wrapper">
    <img src="/assets/2022/imgs/live_title@2x.png" width="301px" height="98px"/>
  </div>


  <div class="gl-min-width imgs-wrapper" style="margin-bottom: 120px;">
    <img [src]="S_ROOT_URL + '/app/edu-expo/2022/pc/live_banner@2x.jpg?x-oss-process=image/resize,w_1200'" width="1200px" height="642px" (click)="gopage('/2022/edulive')"/>
  </div>

  <div class="bg8">
    <img src="/assets/2022/imgs/bg8@2x.jpg" width="253px" height="175px"/>
  </div>

  <div class="bg9">
    <img src="/assets/2022/imgs/bg9@2x.jpg" width="375px" height="273px"/>
  </div>

</app-layout-main-2022>