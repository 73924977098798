<app-layout-main [navi]='6' *ngIf="loaded">
  <div class="edulive-wrapper gl-min-width">
    <div class="edulive-content gl-container-width">
        <div class="title">
          <img src="assets/imgs/7/title.png"/>
        </div>

        <ng-container [ngSwitch]="liveState">
          <ng-container *ngSwitchCase="LIVE_BEFORE">
            <div class="no-show-group">
              <img src="assets/imgs/7/no-show.png" />
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="LIVE_ADVANCE">
            <div class="no-live-wrapper">
              <div class="video-group">
                <div class="vg-body">
                  <div class="vg-poster">
                    <img src="assets/imgs/7/no-live-df.png"/>
                    <div class="vgp-infogroup">
                      <div class="vgp-body">
                        <div class="txt">最近直播：</div>
                        <div class="txt">{{row.datetime1}}</div>
                        <div class="txt vtitle">{{row.subject}}</div>
                        <div class="txt">主讲人：{{row.anchor}}</div>
                        <div class="txt">
                          <div class="tleft">
                            对话人
                          </div>
                          <div class="tright">
                            <div class="viewer" *ngFor="let vitem of row.interviewee">
                              {{vitem}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vg-title" style="background-image: url('assets/imgs/7/video-title-bg.png')">
                    <div class="txt">直播尚未开始，请在直播开始时观看</div>
                  </div>
                </div>
              </div>
              <!-- <div class="video-list-group">
                <div class="vlg-list" style="background-image: url('assets/imgs/7/live-list-bg.png')">
                  <div class="vlglitem" *ngFor="let item of [1,1,1,1,1,1,1,1,1,1,1,1,1]">
                    <img src="assets/imgs/7/no-live-df.png" />
                    <div class="vtitle">直播标题直播标题直播标题</div>
                    <div class="time">开播时间：2021年06月07日 12:00 ～ 13:00</div>
                    <div class="intro">教育局领导谈未来教育方向，教育局领导 谈未来教育方向，教育局领导谈未来教育 方向，教育局领导谈未来教育方向</div>
                  </div>
                </div>
                <div class="vlg-btns">
                  <div class="btn pre"><img src="assets/imgs/7/top1.png"/></div>
                  <div class="btn next"><img src="assets/imgs/7/top1.png"/></div>
                </div>
              </div> -->
            </div>
            <div class="live-bg">
              <img src="assets/imgs/7/live-video-bg.png"/>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="LIVE_ING">
            <div class="online-live-wrapper">
              <div class="video-group-new">
                <video
                    #livevideo
                    controls
                    poster="assets/imgs/index/edu-video-demo.png"
                    class="video-js vjs-default-skin vjs-big-play-centered"
                    width="100%"
                    height="100%"
                    data-setup='{"liveui": true}'
                    disablePictureInPicture
                  >
                  <source [src]="row.hls" type="application/x-mpegURL">
                  <p>你的浏览器不支持 <code>Video</code> </p>
                </video>
                <div class="game-wrapper">
                <div class="game-circle-new" style="text-align: center;">
                  <img src="assets/imgs/7/pqrcode-new.png"/>
                </div>
              </div>
              </div>
              <div class="video-group">
                <!-- <div class="vg-body">
                  <div class="vg-video">
                    <video
                        id="fullscreen-video"
                        name="media"
                        poster="assets/imgs/index/edu-video-demo.png" preload="auto"
                        playsinline="true" webkit-playsinline="true" x5-video-player-type="h5-page" >
                        <source type="video/mp4" src="https://expo-u.oss-cn-shanghai.aliyuncs.com/miniprogram/vod.linktour.com/431dbaede88d4dadab25ab2ff48e3405/ea937a0657294a1db78aaa0db6886ee8-ddf39bfb7ac7526476bc6e9e30a3a51b-sd.mp4?x-oss-process=video/snapshot,t_1,f_jpg,w_2880,h_1152,m_fast" >
                        <p>你的浏览器不支持 <code>Video</code> </p>
                    </video>
                    <div class="play-group">
                      <div class="pgbtn">
                        <img src="assets/imgs/common/i-play.png"/>
                      </div>
                    </div>
                  </div>
                  <div class="vg-title" style="background-image: url('assets/imgs/7/video-title-bg.png')">
                    <div class="vgtitle">暂无直播</div>
                    <div class="hot">
                      <div class="txt">当前直播人气值：</div>
                      <img src="assets/imgs/common/fire.png"/>
                      <div class="txt fire">874747</div>
                    </div>
                  </div>
                </div> -->

<!--
                <div class="game-wrapper">
                  <div class="game-circle-new" style="text-align: center;">
                    <img src="assets/imgs/7/pqrcode-new.png"/>
                  </div>
                </div> -->
                <!-- <div class="game-wrapper">
                  <div class="game-circle">
                    <img src="assets/imgs/7/circle-game.png"/>
                  </div>
                  <div class="list-result">
                    <div class="btn"><img src="assets/imgs/7/me-game.png"/></div>
                    <div class="lrows">
                      <div class="lrow" *ngFor="let item of [1,1,1,1,1,1]">
                        2021-05-24 18:09:24，恭喜“会飞的牙”，抽中 10 元电话费。
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="video-list-group">
                <div class="vlg-list" style="background-image: url('assets/imgs/7/live-list-bg.png')">
                  <div class="vlglitem" *ngFor="let item of [1,1,1,1,1,1,1,1,1,1,1,1,1]">
                    <img src="assets/imgs/7/no-live-df.png" />
                    <div class="vtitle">直播标题直播标题直播标题</div>
                    <div class="time">开播时间：2021年06月07日 12:00 ～ 13:00</div>
                    <div class="intro">教育局领导谈未来教育方向，教育局领导 谈未来教育方向，教育局领导谈未来教育 方向，教育局领导谈未来教育方向</div>
                  </div>
                </div>
                <div class="vlg-btns">
                  <div class="btn pre"><img src="assets/imgs/7/top1.png"/></div>
                  <div class="btn next"><img src="assets/imgs/7/top1.png"/></div>
                </div>
              </div> -->

              <div class="popup-qrcode" *ngIf="showPopup">
                <img src="assets/imgs/7/pqrcode-new.png" />
                <div class="close" (click)="showPopup = false">
                  <img src="assets/imgs/7/close.png" />
                </div>
              </div>
            </div>
            <div class="live-bg">
              <img src="assets/imgs/7/live-video-bg.png"/>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
    </div>
  </div>
  <div class="gap-wrapper gl-min-width" *ngIf="[1,2].includes(liveState)">
    <img src="/assets/imgs/common/gap-bg6.png"/>
  </div>
</app-layout-main>
