import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-2022',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit {

  @Input() show: boolean  
  @Input() html: string 
  @Output() showChange = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.show = false
    this.showChange.emit(this.show);
  }

  noC(e) {
    e.stopPropagation();
    return false;
  }

}
