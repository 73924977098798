import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-lookguide-2022-tab2',
  templateUrl: './tab2.component.html',
  styleUrls: ['./tab2.component.styl']
})
export class LookguideTab2Component implements OnInit {

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {

  }

}
