import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import videojs from 'video.js';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-edulive',
  templateUrl: './edulive.component.html',
  styleUrls: ['./edulive.component.styl']
})
export class EduliveComponent implements OnInit {

  @ViewChild('livevideo') livevideo: ElementRef;
  showPopup: boolean = false
  liveState: number = 0
  video = {
    url: 'http://live.chinaerei.com/505c2bff633a4ddc9a4350e8a7a4d723/161a84e468f04f5da2c5188fa210bf5d-fef77969e8c34fd3d867e42ebbfcc0df-sd.m3u8',
    poster: 'assets/imgs/index/edu-video-demo.png'
  }
  player: videojs.Player;
  // 0 未开始
  // 1 无直播
  // 2 直播中
  constructor(
    private http: HttpClient,
  ) { }

  rows: any;
  loaded = false;
  LIVE_BEFORE = 1;
  LIVE_ADVANCE = 2;//预告
  LIVE_ING = 3;//直播中
  LIVE_END = 4;
  row: any;
  livingurl = 'http://live.chinaerei.com/505c2bff633a4ddc9a4350e8a7a4d723/161a84e468f04f5da2c5188fa210bf5d-fef77969e8c34fd3d867e42ebbfcc0df-sd.m3u8'

  ngOnInit() {
    this.getdata()
  }

  getdata(){
    this.http.get('/pc/livenew').subscribe((res:any) => {
      const row = res;
      // const nowdate = this.util.getnowtime();
      // console.log(nowdate);
      // const nowsts = Date.parse(new Date().toString()) / 1000;
      // for (let xi = 0; xi < xrows.length; xi++) {
        // xrows[xi].showcountdown = false
        // xrows[xi].countdown = 0
        // if (nowsts < xrows[xi].dt1) {
        //   this.liveState = this.LIVE_ADVANCE
        //   // xrows[xi].status = this.LIVE_BEFORE;
        //   // if(xrows[xi].dt1 - nowsts <= 300){
        //   //   xrows[xi].showcountdown = true;
        //   //   xrows[xi].countdown = xrows[xi].dt1 - nowsts;
        //   //   this.showInterval(xi);
        //   // }
        //   this.row = xrows[xi]
        // }
        // if (nowsts >= xrows[xi].dt1 && nowsts <= xrows[xi].dt2) {
        //   this.liveState = this.LIVE_ING
        //   this.livingurl = xrows[xi]['hls'];
        //   setTimeout(() => {
        //     this.goplay();
        //   }, 1000);
        // }
        // if (nowsts > xrows[xi].dt2) {
        //   this.liveState = this.LIVE_END
        // }
        // break
      // }

      // if (xrows.length == 0) {
      //   this.liveState = this.LIVE_BEFORE
      // }

      // console.log(xrows);
      if (row.livestatus) {
        if (row.livestatus == 1) {
          this.liveState = this.LIVE_ADVANCE;
        }

        if (row.livestatus == 2) {
          this.liveState = this.LIVE_ING;
          setTimeout(() => {
            this.goplay()
          }, 1000);
        }
      }else{
         this.liveState = this.LIVE_BEFORE
      }
      this.row = row;
      this.loaded = true;
    })
  }

  goplay(){
    const that = this;
    // window.onload = function() {
      // that.player = videojs(that.livevideo.nativeElement);
      // that.player.play();
      that.player = videojs(that.livevideo.nativeElement, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: "true",
        //自动播放属性,muted:静音播放
        // autoplay: "true",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        controlBar: { // 设置控制条组件
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            {name: 'playToggle'}, // 播放按钮
            {name: 'currentTimeDisplay'}, // 当前已播放时间
            {name: 'progressControl'}, // 播放进度条
            {name: 'durationDisplay'}, // 总时间
            {
              name: 'volumePanel', // 音量控制
              inline: false, // 不使用水平方式
            },
            {name: 'FullscreenToggle'} // 全屏
          ]
        }
    }, function onPlayerReady() {
        console.log('onPlayerReady', that);
        // that.player.play();
      });
    // };
  }
  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}
