import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from '../app.guard';
import { IndexComponent } from './2022';

const routes: Routes = [
  {
    path: "",
    children: [
      { path: "2022-bak-kubang", canActivate: [AppGuard], component: IndexComponent, pathMatch: "full", data: { title: '2022教育博览会实时数据' }},
  ]},
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EchartsRoutingModule { }
