<app-layout-main-2022 [navi]="7">
   <div class="banner-wrapper gl-min-width">
     <img src="/assets/2022/imgs/list_banner01@2x.jpg" height="200px" width="100%"/>
     <div class="title">新闻视点</div>
   </div>
   <div class="tabnav">
     <div class="nav">
       <a href="/2022">HOME</a>&ensp;&ensp;/&ensp;&ensp;新闻视点&ensp;&ensp;/&ensp;&ensp;正文
     </div>
   </div>

   <div class="contains">
      <!-- <div class="tag-row" *ngIf="false">
        <div class="tag" *ngFor="let tag of tags">{{tag}}</div>
      </div> -->
      <div class="author">{{row.author}}</div>
      <div class="title">{{row.name}}</div>
      <div class="content" [innerHTML]="row.content | html"></div>
   </div>

   <img src="assets/2022/imgs/bg4@2x.jpg" alt="" class="bg1">
   <img src="assets/2022/imgs/bg3@2x.jpg" alt="" class="bg2">
 </app-layout-main-2022>
 