<div class="list"> 
   <div class="item" *ngFor="let item of data;let index=index" (click)="showModal(index)">
      <img [src]="item.cover + '?x-oss-process=image/resize,w_600'" alt="" width="100%">
      <div class="subject"> {{item.subject}}</div>
   </div>
</div>

<app-modal-2022 [(show)]="show_modal">
   <div class="content">
      <div class="ptile">{{row.subject}}</div>
      <div class="description" [innerHTML]="row.content"></div>
      <div *ngFor="let f of row.file" class="ffile">
         <img [src]="f.url" alt="" *ngIf="f.type == 'image'" class="image">
         <video class="video" *ngIf="f.type == 'video'"
            controls
            disablePictureInPicture
         >
         <source [src]="f.url" type="video/mp4">
         <p>你的浏览器不支持 <code>Video</code> </p>
         </video>
      </div>
   </div>
     

</app-modal-2022>